import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-modal";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik } from "formik";
import * as Yup from "yup";
import { httpClient } from "../appUtility/Api";
import {  AUTH_URLS, STRINGS } from "constants/AppConst";
import Logo from "../assets/images/logo2.png";
import Header from "./CommonHeader/header";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { updateToken } from "actions/Auth";
import { setSuperFeatures} from 'actions/Setting'; 

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .required(<IntlMessages id="errors.required" />)
    .min(8, <IntlMessages id="errors.password" />)
    .max(50, <IntlMessages id="errors.password" />)
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}/,
      "Password must be between 8 and 50 characters long, and include at least one number, and one uppercase and lowercase letter"
    ),
  confirmPassword: Yup.string()
    .required(<IntlMessages id="errors.required" />)
    .oneOf([Yup.ref('newPassword'), null], <IntlMessages id="errors.passwordsNotMatch" />),
});

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      hiddenPN:true,
      hiddenPC:true,
      showModal:false,
      success:false,
      message:'',
      tokenPassword:'',

      redirect:false,
      toForgetPassword:'/ea/forgot-password',
    
    };
    this.toggleShowPN = this.toggleShowPN.bind(this);
    this.toggleShowPC = this.toggleShowPC.bind(this);
  }

  toggleShowPN() {
    this.setState({ hiddenPN: !this.state.hiddenPN });
  }
  toggleShowPC() {
    this.setState({ hiddenPC: !this.state.hiddenPC });
  }

  componentDidUpdate() {
    // if (this.props.authUser !== null) {
    //   this.props.history.push("/");
    // }

 }

  handleResetPassword = async (password) => {
    
    let postdata = {
      'new_password':password,
      'verified_token':this.props.token.resetPassword,
      'super_admin':this.props.authUser!=null?this.props.authUser.super_admin:false
    };
    
    this.setState({
      isLoading: true,
    });
  
    var res = await httpClient
      .post(AUTH_URLS.SET_PASSWORD, postdata)
      .then((res) => { return res.data; })
      .catch((error) => {
        console.log(error.response.data);
        this.state.error = error.response.data.error;
        return {status:'fail', isLoading:false};
      });
       
      if (res.status === "success") {
          this.setState({ success: true, message:res.message , showModal:true, isLoading:false });
          this.props.setSuperFeatures({
            passwordUpdate:1
          });
          //{"status":"success","message":"Successfully updated!"}
      }

    }

   render() {
    const { isLoading, success, showModal, message } = this.state;
    const { resetPassword } = this.props.token;
    const {locale}= this.props;

    if (resetPassword == '') {
      return <Redirect to={this.state.toForgetPassword} />
    }

    return (
        <div className="w-100 bg-gray m-h-100 signin-page">
        <Header/>
        <div className="container">
        
            <div className="row align-items-center mt-4">
                <div className="col-md-5">
                  <div className="left-section">
                    <div className="logo">
                      <img src={Logo} alt="Logo" className="logo" />
                    </div>
                    {/* <div className="already-text">
                      <span className="d-inline-block mr-2"><IntlMessages id="vivalife.donthaveaccount" /></span>
                      <Link to="/ea/signup"><IntlMessages id="vivalife.registernow" /></Link>
                    </div> */}
                  </div>  
                </div> 
                <div className="col-md-7">
                    <div className="app-login-content">
                  <div className="app-login-header mb-4">
                    <h1>
                      <IntlMessages id="vivalife.resetPassword" />
                    </h1>
                  </div>

                 <div className="app-login-form">
                  <Formik
                    initialValues={{ newPassword: "",confirmPassword:""  }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      //console.log(values);
                      this.handleResetPassword(values.newPassword);
                    }}
                  >
                    {({ handleSubmit, handleChange, handleBlur, values, errors,touched }) => (
                      <form onSubmit={handleSubmit}>
                        <fieldset>
                        <div className="form-group">
                          <label>{<IntlMessages id="form.newPassword" />}<span className="text-red">*</span></label>
                          <input
                            label={<IntlMessages id="form.newPassword" />}
                            type={this.state.hiddenPN ? 'password' : 'text'}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={STRINGS[locale.locale].newpassword}
                            defaultValue={values.newPassword}
                            name="newPassword"
                            margin="normal"
                            className="field form-control"
                          />
                           <VisibilityIcon className="icon" onClick={this.toggleShowPN} />
                           <p className="error"> {errors.newPassword && touched.newPassword ? (
                                // <span>{errors.newPassword}</span>
                                errors.newPassword !=null && <span><IntlMessages id="errors.password" /></span>
                          ) : null} </p>
                          </div>
                          <div className="form-group">
                          <label>{<IntlMessages id="form.confirm" />}<span className="text-red">*</span></label>
                          <input
                            label={<IntlMessages id="form.confirm" />}
                            type={this.state.hiddenPC ? 'password' : 'text'}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={STRINGS[locale.locale].confirmpassword}
                            defaultValue={values.confirmPassword}
                            name="confirmPassword"
                            margin="normal"
                            className="field form-control"
                          />
                           <VisibilityIcon className="icon" onClick={this.toggleShowPC} />
                          <p className="error"> {errors.confirmPassword && touched.confirmPassword ? (
                                <span>{errors.confirmPassword}</span>
                          ) : null}
                           </p> 
                          </div>
                          <div className="mb-3 d-flex align-items-center justify-content-between">
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={this.handleSubmit}
                            >
                              <IntlMessages id="form.submit" />
                            </Button>
                          </div>
                        </fieldset>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
                </div>
             </div>
         </div>

        {success && (
         <Modal
         isOpen={showModal}
         contentLabel="Register Verification"
         className="register-modal"
       >
         <div className="modal-dialog modal-dialog-centered">
             <div className="modal-content" >
                 <div className="modal-header">
                    <button className="close" >
                       <Link to="/ea/signin">
                         <span>&times;</span>
                       </Link>
                    </button>
                   {/* <h4 className="modal-title">Success!</h4> */}
                 </div>
                 <div className="modal-body p-0">
                   <p className="mt-3 mb-4 text-center">
                    {message}
                   </p>
                 </div>
             
                 {/* <div className="modal-footer text-center">
                   <button className="btn btn-primary" >
                       <Link to="/ea/signin"><img src={Login} alt="icon" className="icon"/>
                         <IntlMessages id="menu.login" />
                       </Link>
                   </button>
                 </div> */}
             </div>
         </div>
       </Modal>
        )}
         
        {isLoading && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { token, authUser } = auth;
  const { locale } = settings;
  return { token, authUser, locale };
};

export default connect(mapStateToProps, {
  setSuperFeatures,
  updateToken 
})(ForgotPassword);
