


import {FETCH_ANALYTICS_DATA,UPDATE_DATE_RANGE,UPDATE_ACTIVE_DAYS,FETCH_MEMBERS_DATA, UPDATE_SELECTED_ORG, RESET_DASHBOARD, UPDATE_MY_ORGANIZATIONS} from "../constants/ActionTypes";



export const fetchAnalyticsData = (org) => {
  return {
    type: FETCH_ANALYTICS_DATA,
    payload: org
  }
};
export const updateTestTabDateRange = (org) => {
    return {
      type: UPDATE_DATE_RANGE,
      payload: org
    }
  };
  export const updateActiveDays = (org) => {
    return {
      type: UPDATE_ACTIVE_DAYS,
      payload: org
    }
  };
  export const fetchMembersData = (org) => {
    return {
      type: FETCH_MEMBERS_DATA,
      payload: org
    }
  };

  export const updateStateOrg = (org) => {
    return {
      type: UPDATE_SELECTED_ORG,
      payload: org
    }
  };

  export const updateMyOrganizations = (orgs) => {
    return {
      type: UPDATE_MY_ORGANIZATIONS,
      payload: orgs
    }
  };

  export const resetDashboard = (org) => {
    return {
      type: RESET_DASHBOARD,
      payload: org
    }
  };


