import React, { Component, useEffect, useState} from 'react';
import IntlMessages from "util/IntlMessages";
import Cookies from "./../../containers/Popups/Cookies";
import Button from '@material-ui/core/Button';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';

//import SSL from "../../assets/images/positivessl_trust_seal_lg_222x54.png"
//import { TrustLogo } from "https://secure.trust-provider.com/trustlogo/javascript/trustlogo.js";

// useEffect(() => {
//   const script = document.createElement('script');

//   script.src = "https://secure.trust-provider.com/trustlogo/javascript/trustlogo.js";
//   script.async = true;

//   document.body.appendChild(script);

//   return () => {
//     document.body.removeChild(script);
//   }
// }, []);

// const  loadTrustLogo = ()=>{
//   setTimeout(() => {
//     TrustLogo("https://www.positivessl.com/images/seals/positivessl_trust_seal_sm_124x32.png", "POSDV", "none")
//   }, 1000);
// }




const Footer = () => {
  
  const [cookieConcent, updateCookies] = React.useState('');
  const cookie = (localStorage.getItem('cookieConcent') && localStorage.getItem('cookieConcent')!=="1")?true:false;
  const [isShow, toggleCookiePopup] = React.useState(!cookie);

  useEffect(() => {
    // Update the document title using the browser API
      let concent = localStorage.getItem("cookieConcent");
      if(concent!=null){
      let concentDate = JSON.parse(concent).concentDate;
      console.log(concentDate);

      var date1 = new Date(concentDate);
      var date2 = new Date();
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if(Difference_In_Days>180){
        localStorage.removeItem("cookieConcent");
        localStorage.removeItem("concentID");
        toggleCookiePopup(true);
      }
    }


  });


    return (
      <div>
        <div className="cookies">
          <div className="container">
               {/* <div class="row align-items-center">
                  <div class="col-md-9 text-md-left text-center">
                    <p>
                    <IntlMessages id="form.terms1" />
                    <a href="/e/privacy" target="_blank"><IntlMessages id="menu.privacy" /> </a>
                    <IntlMessages id="form.terms2" />
                    <a href="/e/terms" target="_blank"><IntlMessages id="menu.terms" /> </a>
                    </p>
                  </div> 
                  <div class="col-md-3 text-md-right text-center mt-md-0 mt-3">
                    <button type="button" class="btn btn-success" onClick={()=>{ updateCookies(1); localStorage.setItem('cookieConcent', '1');}}> <IntlMessages id="vivalife.accept" /></button>
                    <button type="button" class="btn btn-danger" onClick={()=>{ updateCookies(0); localStorage.setItem('cookieConcent', '0');}}> <IntlMessages id="vivalife.decline" /></button>
                  </div>
                </div> */}
                {isShow && <Cookies formdata = {{showModal:isShow}}  closeModal={toggleCookiePopup.bind(this)} />}
                {!isShow && <Button className="cookies_toggle_btn" onClick={()=>{toggleCookiePopup(true)}}><ToggleOnIcon/></Button>}
                
          </div> 
      </div>
      {/* <footer className="">
        <span className="d-inline-block mb-2"> 
        <IntlMessages id="vivalife.copyright1" />
        {new Date().getFullYear()}
        <IntlMessages id="vivalife.copyright2" />
        </span>
        <div> <img src={SSL}></img></div>
      </footer> */}
      </div>
    );
  }
;

export default Footer;
