import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE, 
  MINI_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH,
  CHANGE_ORGANIZATION,
  IS_REGISTER_BY_TOEKN,
  ACCOUNT_SETTINGS,
  SUPER_FEATURES
} from 'constants/ActionTypes';
import {DARK_INDIGO} from 'constants/ThemeColors';

//const rltLocale = ['ar'];
//console.log(navigator.language);
var lang = navigator.language == 'zh-CN' || navigator.userLanguage =='zh-CN'?'zhs':navigator.language == 'zh-TW' || navigator.userLanguage =='zh-TW'?'zht':navigator.language == 'ms' || navigator.userLanguage =='ms'?'ms':'en';
//console.log(lang);
const initialSettings = {
  navCollapsed: false,
  drawerType: MINI_DRAWER,
  themeColor: DARK_INDIGO,
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: {
    languageId: 'english',
    locale: localStorage.getItem("language")?localStorage.getItem("language"):lang,
    name: 'English',
    icon: 'us'
  },
  orgId:'',
  relKey:'',
  orgName:'',
  regByToken:false,
  regToken:null,
  unRead:0,
  newOrg:null,
  superFeatures:{
    passwordUpdate:1
  }
};

export default (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    case DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case THEME_COLOR:
      return {
        ...state,
        darkTheme: false,
        themeColor: action.color
      };
    case DARK_THEME:
      return {
        ...state,
        darkTheme: !state.darkTheme
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: {...state.locale,
          locale: action.payload
        },
      };
    case SUPER_FEATURES:
    return {
      ...state,
      superFeatures: { ...state.superFeatures,
        passwordUpdate: action.payload
      },
    };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL

      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      };


    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload
      };
    case IS_REGISTER_BY_TOEKN:
      return {
        ...state,
        regByToken: action.payload.regByToken,
        regToken:  action.payload.regToken
      };
    case ACCOUNT_SETTINGS:
    return {
      ...state,
      unRead: action.payload.unRead,
      newOrg:action.payload.newOrg,
    };
    case CHANGE_ORGANIZATION:
      return {
        ...state,
        orgId: action.payload.orgId,
        relKey:action.payload.relKey,
        orgName:action.payload.orgName
      };
    default:
      return state;
  }
};

//export default settings;
