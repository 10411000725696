import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isIOS, isMobile } from 'react-device-detect';
import Sidebar from '../superadmin/components/sidebar/index';
import Header from './components/header';

import asyncComponent from '../../../util/asyncComponent';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';


class Editor extends React.Component {

  componentWillMount() {
  
  }

  render() {

    const {
      match,
    } = this.props;
      

    return (
      
      <div className={'app-container  mini-drawer'}>
        <Sidebar />  
        <div className="app-main-container">
        
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
            <Header/>
              <div className="app-wrapper echo-editor">
              { this.props.authUser.super_admin &&  
                <Switch>              
                  {/* <Redirect exact from={`${match.url}`} to={`${match.url}/dashboard`} /> */}

                  <Route
                    path={`${match.url}/dashboard`}
                    component={asyncComponent(() => import("./routes/app"))}
                  />

                  <Route
                    path={`${match.url}/new`}
                    component={asyncComponent(() => import("./routes/processed"))}
                  />

                  <Route
                    path={`${match.url}/appusers/inactive`}
                    component={asyncComponent(() => import("./routes/appusers"))}
                  />

                  <Route
                    path={`${match.url}/appusers/monthly`}
                    component={asyncComponent(() => import("./routes/appusers/monthly"))}
                  />

                  <Route
                    path={`${match.url}/globle/pushnotification`}
                    component={asyncComponent(() => import("./routes/globlepn"))}
                  />

                  <Route
                    path={`${match.url}/globle/pushnotifications`}
                    component={asyncComponent(() => import("./routes/pns"))}
                  />
                
                  <Route component={asyncComponent(() => import('app/modules/extraPages/routes/404'))} />
                </Switch>
              }

                 { !this.props.authUser.super_admin && 
                  <Redirect to={'/ea/dashboard/'}/> }
              </div>
            </div>
            {/* <Footer /> */}
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth}) => {
 // const {match  } = settings;
  const { authUser, initURL, login } = auth;
  return {  authUser, initURL, login};
};
export default withRouter(connect(mapStateToProps, {
 
})(Editor));
