import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import indigoTheme from "./themes/indigoTheme";
import AppLocale from "../lngProvider";
import { DARK_INDIGO, INDIGO } from "constants/ThemeColors";
// CSS //
import "assets/vendors/style";
import "../assets/vendors/style.scss"
// CSS //
// pages-components //
import MainApp from "app/modules/dashboard/index";
import SAApp from "app/modules/superadmin/index";
import Index from "./Index";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import InvalidUrl from '../app/modules/extraPages/routes/invalidUrl'
import VerifyUserEmail from "./VerifyUserEmail";

import AboutUs from "../app/modules/extraPages/routes/aboutUs";
import FAQ from "../app/modules/extraPages/routes/faq";
import Terms from "../app/modules/extraPages/routes/terms";
import Privacy from "../app/modules/extraPages/routes/privacy";

import EAAboutUs from "../app/modules/extraPages/routes/ea/aboutUs";
import EAFAQ from "../app/modules/extraPages/routes/ea/faq";
import EATerms from "../app/modules/extraPages/routes/ea/terms";
import EAPrivacy from "../app/modules/extraPages/routes/ea/privacy";
import EAContactUs from "../app/modules/extraPages/routes/ea/contactUs";
import EAGuide from "../app/modules/extraPages/routes/ea/guide";

// pages //

import { setInitUrl, userSignOut } from "../actions/Auth";
import {updateStateOrg, resetDashboard} from 'actions/DashboardAction'; 
import {UpdateRegister,switchLanguage} from 'actions/Setting'; 
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";
import { isTokenExipred } from 'appUtility/commonFunction';



const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: props.location=="/ea/singin" ?"/ea/singin" :"/",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  // componentWillMount() {
  //   window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
  //   if (this.props.initURL === "") {
  //     this.props.setInitUrl(this.props.history.location.pathname);
  //   }
  // }



  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }  
      case DARK_INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      default:
        createMuiTheme(indigoTheme);
    }
    return applyTheme;
  }

  componentDidMount = ()=>{
    localStorage.setItem("TimeZone",Intl.DateTimeFormat().resolvedOptions().timeZone); 
  }

  render() {
    const {
      match,
      locale,
      location,
      authUser,
      initURL,
    } = this.props;
     let applyTheme = createMuiTheme(indigoTheme);

    const params = new URLSearchParams(window.location.search)
    const token =params.has('token') || params.has('_token') || params.has('access_token')?true:null;
    const _email =params.has('email') ?params.get('email') :null;
    var sameacount= false;

    var isLanguage=params.has('lang')?true:false;
    

    if(isLanguage){
      var xlocal='en';
      if(params.get('lang')==='zhHant'){
        xlocal = 'zht';
      }else if(params.get('lang')==='zhHans'){
        xlocal = 'zhs';
      }else if(params.get('lang')==='en'){
        xlocal = 'en';
      }else if(params.get('lang')==='ms'){
        xlocal = 'ms';
      }
      if(locale.locale != xlocal){
        localStorage.setItem("language",xlocal);
        this.props.switchLanguage(xlocal);
      }
    }

    //const access_token = || params.has('access_token')?true:null;
    //http://localhost:3000/ea/signin?_token=0ysERpCdqCm4WrvUloA8zKKNDj8Qf6tJr3ub9QttOh&email=jbl@yopmail.com
    if((token) && !this.props.regByToken ){
      if(this.props.authUser!= null && _email == this.props.authUser.email){
        //this.props.history.push("/ea/messages");
        //sameacount= true;
        return ( <Redirect to={'/ea/messages'}/> );
      }else{
      this.props.resetDashboard();
      this.props.updateStateOrg({});
      this.props.UpdateRegister({regByToken:false,regToken:''});
      this.props.userSignOut();
      }
      //this.props.UpdateRegister({regByToken:false,regToken:token});
    }

    if(isTokenExipred()){
      this.props.userSignOut();
    }
    
    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/index'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/ea/signin' || initURL === '/ea/signup' || initURL === '/ea/forgot-password') {
        //if(sameacount)
        return ( <Redirect to={'/ea/dashboard/'}/> );
        //else
        //this.props.history.push("/ea/messages");
        // if(this.props.authUser.super_admin){
        //   return ( <Redirect to={'/sa/dashboard/'}/> );
        // }else{
        //   return ( <Redirect to={'/ea/dashboard/'}/> );
        // }
      } else {
        this.props.UpdateRegister({regByToken:false, regToken:''});
         return ( <Redirect to={initURL}/> );
      }
    }

    // if(this.props.login){
    //   if(this.props.authUser.super_admin){
    //     return ( <Redirect to={'/sa/dashboard/'}/> );
    //   }else{
    //     return ( <Redirect to={'/ea/dashboard/'}/> );
    //   }
    // }

    // if (isDirectionRTL) {
    //   applyTheme.direction = "rtl";
    //   document.body.classList.add("rtl");
    // } else {
    //   document.body.classList.remove("rtl");
    //   applyTheme.direction = "ltr";
    // }

    window.addEventListener('storage', e => { 
      if(e.key === 'tokens' && e.oldValue && !e.newValue) {
        //this.props.userSignOut();
        window.location.reload();
      }else if (e.key === 'tokens' && !e.oldValue && e.newValue){
        window.location.reload();
      }
    });

    const currentAppLocale = AppLocale[locale.locale]; //zhs, zht, en
    return (
   
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                <Switch>
                <Route path='/ea/signin'  component={SignIn}/>
                <Route path='/ea/signup' component={SignUp}/>
                {/* <Route path='/ea/register' component={Register}/> */}
                <Route path='/ea/forgot-password' component={ForgotPassword}/>
                <Route path='/ea/reset-password' component={ResetPassword}/>
                <RestrictedRoute path={`${match.url}ea`} authUser={authUser} component={MainApp}/>

                <RestrictedRoute path={`${match.url}sa`} authUser={authUser} component={SAApp}/>
                                  
                  <Route path='/index' component={Index}/>
                 
                  <Route
                    path="/verifyUserEmail/:verificationCode"
                    component={VerifyUserEmail}
                  />
                  <Route path='/about' component={AboutUs}/>
                  <Route path='/faq' component={FAQ}/>
                  <Route path='/terms' component={Terms}/>
                  <Route path='/privacy' component={Privacy}/>

                  <Route path='/e/about' component={EAAboutUs}/>
                  <Route path='/e/faq' component={EAFAQ}/>
                  <Route path='/e/terms' component={EATerms}/>
                  <Route path='/e/privacy' component={EAPrivacy}/>
                  <Route path='/e/guide' component={EAGuide}/>
                  <Route path='/e/contact-us' component={EAContactUs}/>
                  
                  {/* <Route path='/ea/error-invalidurl' component={InvalidUrl}/>   */}
                  <Route
                    component={asyncComponent(() =>
                      import("../app/modules/extraPages/routes/404")
                    )}
                  />
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const {
    themeColor,
    sideNavColor,
    darkTheme,
    locale,
    isDirectionRTL,
    regByToken
  } = settings;
  const { authUser, initURL, login } = auth;
  return {
    themeColor,
    sideNavColor,
    isDarkTheme: darkTheme,
    locale,
    isDirectionRTL,
    authUser,
    initURL,
    regByToken,
    login
  };
};

export default connect(mapStateToProps, {setInitUrl, userSignOut,switchLanguage,  resetDashboard, updateStateOrg, UpdateRegister })(App);
// export default connect(mapStateToProps)(App);
