import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import Register from "../../assets/images/join.png";
import Login from "../../assets/images/login.png";
import Terms from "../../assets/images/terms.png";
import Privacy from "../../assets/images/privacy.png";
import About from "../../assets/images/info.png";
import Lang from "../../assets/images/lang.png";
import Home from "../../assets/images/home.png";
import Dashboard from "../../assets/images/dashboard.png";
import Contact from "../../assets/images/contact_icon.png";
import Faq from "../../assets/images/faq_icon.png";
import {switchLanguage} from '../../actions/Setting';
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { httpClient } from '../../appUtility/Api';
import {LANGUAGES, PROFILE_URLS} from  "constants/AppConst";


class Menu extends React.Component {
  
  constructor() {
    super();
    this.state = {
      menu: false,
    };
  }

  componentDidMount() {
    const language = localStorage.getItem("language");
    if(!language){
      localStorage.setItem('language', this.props.locale);
    }
  }

  togglefn2() {
    this.setState({
      menu: !this.state.menu
    });
  }
  // onSelectLangauge=(e)=>{
  //   e.preventDefault();
  //   this.props.switchLanguage(e.target.value=="en"?"en":e.target.value);
  //   localStorage.setItem('language', e.target.value);
  // }

  onSelectLangauge=(e, l)=>{
    const {locale, authUser} = this.props;
    //const { authUser } = this.props.auth;
    var lang = locale.locale;
    var locallang = e.target.value;
    if(locallang == 'zht' ){
      lang= 'zhHant';
    }else if(locallang =='zhs'){
      lang= 'zhHans';
    }else if(locallang =='ms'){
      lang= 'ms';
    }else{
      lang= 'en';
    }
  
    if(this.props.login){
    var postdata = { first_name: authUser.first_name, last_name: authUser.last_name, language: lang};

    httpClient
      .post(
        PROFILE_URLS.UPDATE_PROFILE_INFO,
        postdata
      )
      .then((res) => { 
        if(res.data.status == "success"){
          this.props.switchLanguage(locallang=="en"?"en":locallang);
          localStorage.setItem('language', locallang);
          console.log("language updated");
        }
      })
      .catch((error) => {
        console.log("Error while update language");
      });
    }else{
      this.props.switchLanguage(locallang=="en"?"en":locallang);
      localStorage.setItem('language', locallang);
    }
  }

  render() {
    const {
      locale,
      login
    } = this.props;
    //const {first_name, last_name}= this.props.authUser;
    //console.log('Locale',this.props);
    return (
                <div>
                  <div  className="burger-menu" >
                    <button className="btn rounded-10" type="button" onClick={() => this.togglefn2()}>
                    <MenuIcon className="icon"/>
                    </button>
                    {this.state.menu ? (
                        <>
                          <div className="burger-dropdown">
                          {/* <ClearIcon className="icon" onClick={() => this.togglefn2()}/> */}
                              <ul>
                                  <li><Link to="/index"><img src={Home} alt="icon" className="icon"/>
                                    <IntlMessages id="menu.home" />
                                  </Link></li>
                                  {/* {!login  && ( <li><Link to="/ea/register"><img src={Register} alt="icon" className="icon"/>
                                    <IntlMessages id="menu.register" />
                                  </Link></li> )} */}
                                  {!login  && ( <li><Link to="/ea/signin"><img src={Login} alt="icon" className="icon"/>
                                    <IntlMessages id="menu.login" />
                                    </Link></li> )}
                                  {login  && ( <li><Link to="/ea/dashboard"><img src={Dashboard} alt="icon" className="icon"/>
                                    <IntlMessages id="sidebar.dashboard" />
                                    </Link></li> )}
                                  <li className="submenu">
                                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img src={About} alt="icon" className="icon"/> <IntlMessages id="menu.guide" />
                                      </a>
                                      <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" href="/faq"><IntlMessages id="menu.app" /></a>
                                        <a className="dropdown-item" href="/e/guide"><IntlMessages id="vivalife.ea" /></a>
                                      </div>
                                    {/* <Link to="/e/faq">
                                      <img src={Guide} alt="icon" className="icon"/>
                                      Guide
                                    <IntlMessages id="menu.faq" />
                                  </Link> */}
                                  </li>
                                  <li><Link to="/e/faq"><img src={Faq} alt="icon" className="icon"/>
                                    <IntlMessages id="menu.faq" />
                                      
                                  </Link></li>
                                  <li><Link to="/e/contact-us"><img src={Contact} alt="icon" className="icon"/>
                                    <IntlMessages id="sidebar.aboutapp.contactus" />
                                  </Link></li>
                                  <li><Link to="/e/terms"><img src={Terms} alt="icon" className="icon"/>
                                    <IntlMessages id="menu.terms" />
                                  </Link></li>
                                  <li><Link to="/e/privacy"><img src={Privacy} alt="icon" className="icon"/>
                                    <IntlMessages id="menu.privacy" />
                                  </Link></li>
                                  {this.props.data.isHome  && (<li className="lang-select">
                                    <img src={Lang} alt="icon" className="lang-icon"/>
                                      <select onChange={this.onSelectLangauge} defaultValue={locale}>
                                        {LANGUAGES.map((option, key) => (
                                        <option key={key} value={option.value}>{option.label}</option>
                                        ))}
                                      </select> 
                                      {/* <i className="fas fa-sort-down"></i> */}
                                  </li>)}
                              </ul>
                              </div>
                        </>
                      ) : null}
                </div>
                </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const {locale}=settings.locale;
  const login = auth.login;
  const {authUser} = auth;
  
  return { locale, login ,authUser };
  };
  
  export default connect(mapStateToProps, {
    switchLanguage
  })(Menu);