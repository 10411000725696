import React from 'react';
// import { Link } from "react-router-dom";
// import Avatar from '@material-ui/core/Avatar'
import {connect} from 'react-redux'
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import {userSignOut} from 'actions/Auth';
// import IntlMessages from 'util/IntlMessages';
class UserInfo extends React.Component { 
  
  componentDidMount() {
    // const user = localStorage.getItem('login_user') ? JSON.parse(localStorage.getItem('login_user')) : '';
    // this.setState({ userName:user.fullName,userImage:user.userImage });
  }

  addDefaultSrc(e) {
    e.target.onerror = null;
    e.target.src = "https://via.placeholder.com/150x150";
  }

  handleClick = event => {
   //
  };

  handleRequestClose = () => {
   //
  };

  
  handleProfileRequest = () => {
   // this.props.history.push('/app/profile');
  };
 

  render() { 
    const {first_name, last_name} = this.props.authUser;
    return (
      <div className="user-profile">
        <div className="user-detail">
          <h6 className="user-name" onClick={this.handleClick}>
          {first_name} {last_name}
          </h6>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {locale} = settings;
  const {authUser} = auth;
  return {locale, authUser}
};
export default connect(mapStateToProps, {userSignOut})(UserInfo);


