import React from 'react';
import Modal from "react-modal";
import IntlMessages from 'util/IntlMessages';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Button from '@material-ui/core/Button';
import Logo from "../../assets/images/logo2.png";
import LockIcon from '@material-ui/icons/Lock';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check'

class RegisterSuccess extends React.Component {

  constructor() {
    super();
    this.state = {
      prefCookie:true,
      necesCookie:true,
      cookie: localStorage.getItem('cookieConcent')&&localStorage.getItem('cookieConcent')!=="1"?true:false,
      concentDate:'',
      concentID:'',
      selectPrefCookie:true,
      tabNumber:0
    };

  }

  onSelectTab(index) {
    //console.log("Tab: "+index);
    this.setState({tabNumber:index});
  }

  togglePrefCookie=()=>{
    this.setState({prefCookie:!this.state.prefCookie})
    //alert(this.state.prefCookie);
  }

  componentDidMount=()=>{
    const cookieString = localStorage.getItem('cookieConcent')?localStorage.getItem('cookieConcent'):null;
    //console.log(cookieString);
    
    if(cookieString !=null && cookieString!=="1" ){
      const cookie = JSON.parse(cookieString);
      const cid = localStorage.getItem('concentID');
      this.cookieState(cookie,cid);
    }
  }

  denyAll=()=>{
    const cookie = {necesCookie:true,prefCookie:false,concentDate:Date()};
    localStorage.setItem('cookieConcent',JSON.stringify(cookie));
    localStorage.setItem('concentID',btoa(JSON.stringify(cookie)));
    this.cookieState(cookie,btoa(JSON.stringify(cookie)));
  }
  allowAll=()=>{
    const cookie = {necesCookie:true,prefCookie:true, concentDate:Date()};
    localStorage.setItem('cookieConcent',JSON.stringify(cookie));
    localStorage.setItem('concentID',btoa(JSON.stringify(cookie)));
    this.cookieState(cookie,btoa(JSON.stringify(cookie)));
  }
  allowSelection=()=>{
    const cookie = {necesCookie:true,prefCookie:this.state.selectPrefCookie,concentDate:Date()};
    localStorage.setItem('cookieConcent',JSON.stringify(cookie));
    localStorage.setItem('concentID',btoa(JSON.stringify(cookie)));
    this.cookieState(cookie,btoa(JSON.stringify(cookie)));
  }

  cookieState=(cookie,cid)=>{
    this.setState({
      necesCookie:cookie.necesCookie,
      prefCookie:cookie.prefCookie,
      selectPrefCookie:cookie.prefCookie,
      concentDate:cookie.concentDate,
      concentID:cid,
      cookie:true,
      tabNumber:3
    })
  }

  render() {
    const { locale } = this.props;
    const { necesCookie, prefCookie, cookie,concentID,concentDate, tabNumber}  = this.state;
    return (
      <Modal
        isOpen={this.props.formdata.showModal }
        contentLabel="Register Verification"
        className="register-modal"
        id="cookie_modal"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">

            <div className="modal-content" >

              <div className="modal-header">
                <div className="powered_by">
                       <span>Powered by </span>
                       <img src={Logo} alt="icon" className="powered_logo"/>
                       </div>
                    {cookie && <button className="close" onClick={()=>{this.props.closeModal(false)}} >
                         <span>&times;</span>
                    </button>}
              </div>

              <div className="modal-body p-0">
                {/* <p className="mt-3 mb-4 text-center">
                  This is data 
                </p> */}
                <Tabs onSelect={(index) => this.onSelectTab(index)} selectedIndex={tabNumber}>
            <TabList>
              <Tab> 
                <IntlMessages id="cookie.consent" />
              </Tab>
              <Tab>
                <IntlMessages id="cookie.details" />
              </Tab>
              <Tab>
                <IntlMessages id="cookie.aboutCookie" />
              </Tab>
              {cookie && <Tab>
                <IntlMessages id="cookie.currentState" />
              </Tab>}
             
            </TabList>
          
            <TabPanel>
              <h4> <IntlMessages id="cookie.cookies1" /></h4>
              <IntlMessages id="cookie.cookies2" />

                  <div  className="cookie_buttons text-center"> 
                    <Button onClick={()=>{this.denyAll()}} className=""><IntlMessages id="vivalife.decline" /></Button>
                    <Button onClick={() => this.onSelectTab(1)} className=""><IntlMessages id="tab.header.settings" /></Button>
                    <Button onClick={()=>{this.allowAll()}} className=""><IntlMessages id="vivalife.accept" /></Button>
                   </div>

            </TabPanel>
            <TabPanel>
              <div>
                <ul>
                  
                  <li className="mb-3">
                    <div className="row m-0">
               
                     <div className="col-8 pl-0">
                     <h4> <IntlMessages id="cookie.essential" /></h4>
                     <IntlMessages id="cookie.essentialCookies" />
                       </div>
                   <div className="col-4"> 
                   <div className="text-right">
                    <label className="switch">
                      <input type="checkbox" checked disabled={true} />
                      <span className="slider round"></span>
                    </label>
                   </div>
                     
                  </div>
                  </div>

                    {/* <Button onClick={()=>{this.togglePrefCookie()}}> Toggle </Button> */}
                  </li>

                  <li>
                    <div className="row m-0">
               
                     <div className="col-8 pl-0">
                     <h4> <IntlMessages id="cookie.preference" /></h4>
                     <IntlMessages id="cookie.preferenceCookies" />
                       </div>
                   <div className="col-4"> 
                   <div className="text-right">
                    <label className="switch">
                      <input type="checkbox" onChange={(e)=>{ this.setState({selectPrefCookie:e.target.checked})}} defaultChecked={prefCookie} />
                      <span className="slider round"></span>
                    </label>
                   </div>
                     
                  </div>
                  </div>

                    {/* <Button onClick={()=>{this.togglePrefCookie()}}> Toggle </Button> */}
                  </li>
                </ul>
              </div>
              <div className="cookie_buttons text-center"> 
                    <Button onClick={()=>{this.allowSelection()}}><IntlMessages id="cookie.acceptSelection" /></Button>
              </div>
            </TabPanel>
            <TabPanel className="cookie_about_tab">
                <p> <IntlMessages id="cookie.cookies3" /> </p>
                <p><IntlMessages id="cookie.cookies3.1" /> </p>
                <p><IntlMessages id="cookie.cookies3.2" /> </p>
                <p><IntlMessages id="cookie.cookies3.3" />  </p>
                <p><IntlMessages id="cookie.cookies3.4" />  </p>
              
            </TabPanel>
            {cookie && <TabPanel>
              <div className="current_state_content"> 
              <h4><IntlMessages id="cookie.currentState" /></h4>
              <ul>
                <li> {necesCookie &&  <LockIcon className="icon mr-2 text-purple"/> }<IntlMessages id="cookie.essential" /></li>
                <li> 
                  {!prefCookie && <ClearIcon className="icon mr-2"/> }
                  { prefCookie && <CheckIcon className="icon mr-2 text-success"/> }
                  <IntlMessages id="cookie.preference" />
                </li>
              
              </ul>

                <div className="hide_detail">
                  <div className="consent_date mb-3"> 
                    <label><IntlMessages id="records.date" /></label>
                    <p>{concentDate}</p>
                  </div>
                  <div className="consent_id"> 
                    <label><IntlMessages id="cookie.id" /></label>
                    <p>{concentID}</p>
                  </div>
                </div>
              </div>
            </TabPanel>}
          </Tabs>
              </div>
                {/* <div className="modal-footer">
                  
                  </div> */}
            </div>
        

        </div>
      </Modal>
    );
  }

}

const mapStateToProps = ({ auth, settings }) => {
  const { locale } = settings;
  return { locale};
};

export default  RegisterSuccess;