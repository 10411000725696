import appLocaleData from 'react-intl/locale-data/de';
import zhtMessages from '../locales/zh_Hant.json';

const ZHtLang = {
  messages: {
    ...zhtMessages
  },
  locale: 'en-ZHT',
  data: appLocaleData
};
export default ZHtLang;
