import React ,{ useState, useEffect }  from 'react';
import { httpClient } from '../../../../../../appUtility/Api';
import { STATIC_PAGES_URLS } from '../../../../../../constants/AppConst';
import Parser from 'html-react-parser';
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "../../../../../../containers/StaticHeader/header";

const Privacy = ({match}) =>  {
  const params = new URLSearchParams(window.location.search)
  //const lang =params.has('lang')?params.get('lang'):'en';
  const [privacyBody, setPrivacyBody] = React.useState('');
  const [privacyTitle, setPrivacyTitle] = React.useState('');

  const language = localStorage.getItem("language");
  var lang='en';

  if(language==='zht'){
    lang = 'zhHant';
  }else if(language==='zhs'){
    lang = 'zhHans';
  }else if(language==='ms'){
    lang = 'ms';
  }else if(language==='en'){
    lang = 'en';
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    httpClient.get(STATIC_PAGES_URLS.PRIVACY_WEB+lang)
   .then(results => results.data)
   .then(data => {
    setPrivacyBody(data.detail.body);
    setPrivacyTitle(data.detail.title);
   });
}, []); 

    return (

      <div className="bg-gray w-100 m-h-100"> 
       <Header/>
        {privacyBody !="" &&  Parser(privacyBody)}
        {privacyBody =="" && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
export default Privacy;

