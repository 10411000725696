import React ,{ useState, useEffect }  from 'react';
import { httpClient } from '../../../../../../appUtility/Api';
import { STATIC_PAGES_URLS } from '../../../../../../constants/AppConst';
import Parser from 'html-react-parser';
import Header from '../../../../../../containers/StaticHeader/header';
import CircularProgress from "@material-ui/core/CircularProgress";


const FAQ = ({match}) =>  {
  const params = new URLSearchParams(window.location.search)
  //const lang =params.has('lang')?params.get('lang'):'en';
  const [faqBody, setFaqBody] = React.useState('');
  const [faqTitle,setFaqTitle]= React.useState('');

  const language = localStorage.getItem("language");
  var lang='en';

  if(language==='zht'){
    lang = 'zhHant';
  }else if(language==='zhs'){
    lang = 'zhHans';
  }else if(language==='ms'){
    lang = 'ms';
  }else if(language==='en'){
    lang = 'en';
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    httpClient.get(STATIC_PAGES_URLS.FAQ_WEB+lang)
   .then(results => results.data)
   .then(data => {
    setFaqBody(data.detail.body);
    setFaqTitle(data.detail.title)
   });
}, []); // <-- Have to pass in [] here!


    return (
      <div className="bg-gray w-100 m-h-100"> 
          <Header/>
                  {faqBody !="" && Parser(faqBody)}
                  {faqBody=="" && (
                    <div className="loader-view">
                      <CircularProgress />
                    </div>
                  )}
          </div>
    );
  }

export default FAQ;

