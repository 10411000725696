import React, { useState, useEffect } from 'react';
import '../../inner-style.scss';
import { connect } from 'react-redux';
import { httpClient } from '../../../../../../appUtility/Api';
import { STATIC_PAGES_URLS, STRINGS } from '../../../../../../constants/AppConst';
import Parser from 'html-react-parser';
import CircularProgress from "@material-ui/core/CircularProgress";
import IntlMessages from 'util/IntlMessages';
import { NavLink } from "react-router-dom";

//import Header from '../../../../../../components/Header';
import Header from "../../../../../../containers/HomeHeader/header";
import { sendVerificationEmail } from '../../../../../../appUtility/emailHelper';
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "react-modal";

const validationSchema = Yup.object({
  email: Yup.string()
    .required(<IntlMessages id="errors.required" />)
    .email(<IntlMessages id="errors.emailInvalid" />),
  first_name: Yup.string()
  .required(<IntlMessages id="errors.required" />),
  last_name: Yup.string()
  .required(<IntlMessages id="errors.required" />),
  queryType:Yup.string()
  .required(<IntlMessages id="errors.required" />),
  message:Yup.string()
  .required(<IntlMessages id="errors.required" />),
});


const Terms = ({ match }) => {
  const params = new URLSearchParams(window.location.search)
  //const lang =params.has('lang')?params.get('lang'):'en';
  const [termsBody, setTermsBody] = React.useState('');
  const [termsTitle, setTermsTitle] = React.useState('');

  const [isOpen, toggelModal] = React.useState(false);
  const [APImessage, updateMessage] = React.useState('');
  
  //const {  locale } = this.props;

  const language = localStorage.getItem("language");
  var lang = 'en';

  if (language === 'zht') {
    lang = 'zhHant';
  } else if (language === 'zhs') {
    lang = 'zhHans';
  } else if (language === 'ms') {
    lang = 'ms';
  } else if (language === 'en') {
    lang = 'en';
  }

  // Similar to componentDidMount and componentDidUpdate:
  // useEffect(() => {
  //   httpClient.get(STATIC_PAGES_URLS.TERMS + lang)
  //     .then(results => results.data)
  //     .then(data => {
  //       setTermsTitle(data.detail.title)
  //       setTermsBody(data.detail.body)
  //     });
  // }, []);

  const support= ((first_name, last_name, email, queryType,  message) =>{
    try {
      httpClient
        .post(STATIC_PAGES_URLS.SUPPORT, {
          first_name: first_name,
          last_name: last_name,
          email: email,
          subject: queryType,
          message: message,
        })
        .then((res) => {
          console.log(res.data.message);  
          updateMessage(res.data.message);
          toggelModal(true);
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    } catch (error) {
      console.log("error2");
      console.log(error.response.data.error);
      //showErrorToaster(error)
    }
  });
  
  //const [n, setN] = useState({});

  return (
    
    <div class="bg-gray w-100 m-h-100">
      <Header />
      <div className="main-form">
        <div className="container">
            <div class="contact-content">
              <h1><IntlMessages id="vivalife.contactUs" /></h1>
            </div>
            <Formik initialValues={{ email:'',first_name:"",last_name:"",queryType:"",message:""}}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={(values) => {
                      support(values.first_name, values.last_name, values.email, values.queryType,  values.message);
                    }}
                >

                    {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                    <form onSubmit={handleSubmit}>

            <div className="form-group">
              <label><IntlMessages id="vivalife.queryType" />
              <span className="text-red">*</span>
              </label>
              <select className="form-control" onBlur={handleBlur} name="queryType" onChange={handleChange} >
                <option selected disabled value="" > {STRINGS[language].select} </option>
                <option value={STRINGS[language].accountTechnical}>{STRINGS[language].accountTechnical} </option>
                <option value={STRINGS[language].businessOpportunities}>{STRINGS[language].businessOpportunities}</option>
                <option value={STRINGS[language].feedback}>{STRINGS[language].feedback}</option>
                <option value={STRINGS[language].other}>{STRINGS[language].other}</option>
              </select>
              <p className="error">
                          {errors.queryType && touched.queryType ? (
                                <span>{errors.queryType}</span>
                          ) : null}
                  </p>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-4 col-xl-4">
                  <label><IntlMessages id="form.lastname" /><span className="text-red">*</span></label>
                  <input 
                  type="text" 
                  className="form-control" 
                  name="last_name" 
                  placeholder={STRINGS[language].lastName} 
                  onBlur={handleBlur}
                  onChange={handleChange}
                  />
                  <p className="error">
                          {errors.last_name && touched.last_name ? (
                                <span>{errors.last_name}</span>
                          ) : null}
                  </p>
                </div>
                <div className="col-md-4 col-xl-4">
                  <label><IntlMessages id="form.firstname" /><span className="text-red">*</span></label>
                  <input type="text" onBlur={handleBlur}
                  onChange={handleChange} className="form-control" name="first_name" placeholder={STRINGS[language].firstName} />
                  <p className="error">
                          {errors.first_name && touched.first_name ? (
                                <span>{errors.first_name}</span>
                          ) : null}
                  </p>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label><IntlMessages id="form.emailAddress" /><span className="text-red">*</span></label>
              <input type="email"  onBlur={handleBlur}
                  onChange={handleChange} className="form-control" name="email" placeholder={STRINGS[language].email} />
              <p className="error">
                          {errors.email && touched.email ? (
                                <span>{errors.email}</span>
                          ) : null}
              </p>
            </div>
            {/* <div className="form-group">
              <label>ID™   number</label>
              <input type="text" className="form-control" placeholder="ID™ number" />
            </div> */}
            <div className="form-group">
              <label><IntlMessages id="vivalife.message" /><span className="text-red">*</span></label>
              <textarea cols="5" rows="4" className="form-control" onBlur={handleBlur}
                  onChange={handleChange} name="message" placeholder={STRINGS[language].message}>
              </textarea>
              <p className="error">
                {errors.message && touched.message ? (
                      <span>{errors.message}</span>
                ) : null}
              </p>
                
            </div>
            <div className="text-center">
                <button type="submit" className="btn-send"><IntlMessages id="form.submit" /></button>
              </div>

              </form>
                    )}
                  </Formik>
        </div>
      </div>
      
      <div>
      <Modal
        isOpen={isOpen}
        contentLabel="Register Verification"
        className="register-modal"
      >
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" >

              <div className="modal-header">

                  <NavLink to="/ea/dashboard" className="close"  onClick={()=>{
                    toggelModal(false);
                   }}> 
                          <span  className="sub-nav-text" aria-hidden="true">&times;</span>
                  </NavLink>

                 {/* <button type="button" className="close"  
                > <span>&times;</span>
                  </button>  */}
              </div>

              <div className="modal-body p-0">
                <p className="mt-3 mb-4 text-center">
                  {APImessage}
                </p>
              </div>

              {/* <div className="modal-footer text-center">
                <button className="btn btn-submit1" >
                  Close
                </button>
              </div> */}

              </div>

        </div>
      </Modal>
      </div>
    </div>
  );
}
const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale};
};

export default connect(mapStateToProps, {

})(Terms);
