import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import settings from "./Settings";
import Auth from "./auth";
import CommonReducer from "./CommonReducer";
import ErrorReducer from "./ErrorReducer";
import DashboardReducer from "./DashboardReducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: settings,
    auth: Auth,
    common: CommonReducer,
    dashboard:DashboardReducer,
    nodeError: ErrorReducer,
  });
