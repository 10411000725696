import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { userSignOut } from 'actions/Auth';
import Button from "@material-ui/core/Button";
// import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import MobileFriendly from '@material-ui/icons/MobileFriendly';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { connect } from 'react-redux'
import {switchLanguage} from '../../../../../../src/actions/Setting';
import '../../../../../containers/SideNav/sidebar.scss'
import {LANGUAGES, PROFILE_URLS} from  "constants/AppConst";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import {updateStateOrg, resetDashboard} from 'actions/DashboardAction';
import { httpClient } from '../../../../../appUtility/Api';

class SidenavContent extends Component {

  state = {
    open: false,
    CurrentLanguage:'',
  };


  componentDidMount() {

  }

  handleRequestClose = () => {
    this.props.resetDashboard({}); 
    this.props.updateStateOrg({});
  };

  componentWillReceiveProps(nextProps) {

  }

  onSelectLangauge=(e, l)=>{
    const {locale, authUser} = this.props;
    //const { authUser } = this.props.auth;
    var lang = locale.locale;
    var locallang = e.currentTarget.dataset.id;
    if(locallang == 'zht' ){
      lang= 'zhHant';
    }else if(locallang =='zhs'){
      lang= 'zhHans';
    }else if(locallang =='ms'){
      lang= 'ms';
    }else{
      lang= 'en';
    }
  
    if(this.props.login){
    var postdata = { first_name: authUser.first_name, last_name: authUser.last_name, language: lang};

    httpClient
      .post(
        PROFILE_URLS.UPDATE_PROFILE_INFO,
        postdata
      )
      .then((res) => { 
        if(res.data.status == "success"){
          this.props.switchLanguage(locallang=="en"?"en":locallang);
          localStorage.setItem('language', locallang);
          console.log("language updated");
        }
      })
      .catch((error) => {
        console.log("Error while update language");
      });
    }else{
      this.props.switchLanguage(locallang=="en"?"en":locallang);
      localStorage.setItem('language', locallang);
    }
  }

  render() {

    const { unread } = this.props.authUser;
    const {locale, profile} = this.props;

    const toogleSidebar = () => {
      var ele = document.body;
      ele.classList.add("sideMenu");
      ele.classList.add("change");
    };

    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          {/* <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li> */}

          <li className="no-arrow">
            <Button>
              <NavLink to="/sa/dashboard/" className="link">
              <AddBoxIcon className="icon" />
                <span className="nav-text">
                  {/* <IntlMessages id="sidebar.dashboard" /> */}
                   New Registrations
                </span>
              </NavLink>
            </Button>
          </li>
         
          <li className="no-arrow">
            <Button>
              <NavLink to="/sa/new" className="link">
              <SettingsBackupRestoreIcon className="icon" />
                <span className="nav-text">
                Invited List
                  {/* <IntlMessages id="sidebar.myaccount" /> */}
                </span>
              </NavLink>
            </Button>
          </li>

          <li className="no-arrow">
            <Button>
              <div className="link" data-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1" onClick={() => toogleSidebar()}>
                <MobileFriendly className="icon" />
                <span className="nav-text">
                 App Users
                </span>
                <div className="collapse" id="collapseExample1">
                  <ul className="sub-menu">
                      
                      <li> 
                        <NavLink to="/sa/appusers/inactive" className="link">
                          <span className="sub-nav-text">
                            Inactive
                          </span>
                        </NavLink>
                      </li>

                      <li> 
                        <NavLink to="/sa/appusers/monthly" className="link">
                          <span className="sub-nav-text">
                            Monthly
                          </span>
                        </NavLink>
                      </li>
                      
                  </ul>
                </div>
              </div>
            </Button>
          </li>
          <li className="no-arrow">
            <Button>
              <NavLink to="/sa/globle/pushnotifications" className="link">
              <NotificationsIcon className="icon" />
                <span className="nav-text">
                Globle PN
                </span>
              </NavLink>
            </Button>
          </li>
          

{/*           
          <li className="no-arrow">
            <Button>
              <NavLink to="/ea/messages" className="link">
                <MailOutlineIcon className="icon" />
                {profile.unread !== 0 && <span className="badge badge-msg badge-danger ml-2">{profile.unread}</span>}
                <span className="nav-text">
                  <IntlMessages id="sidebar.messagecenter" />
                  {/* Message Center */}
                  {/* {unread !==0 && <span className="badge badge-light ml-2">
                    {unread}</span> 
                  } ***
                </span>
              </NavLink>
            </Button>
          </li>
          <li className="no-arrow">
            <Button>
              <div className="link" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" onClick={() => toogleSidebar()}>
                <InfoIcon className="icon" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.about" />
                  {/* About Viva Life **
                </span>
                <div className="collapse" id="collapseExample">
                  <ul className="sub-menu">
                    <li>
                      <NavLink to="/e/about" className="link">
                        <span className="sub-nav-text">
                          <IntlMessages id="menu.aboutus" />
                          {/* About Us ***
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/e/terms" className="link">
                        <span className="sub-nav-text">
                          <IntlMessages id="menu.terms" />
                          {/* Terms of Use **
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/e/privacy" className="link">
                        <span className="sub-nav-text">
                          <IntlMessages id="menu.privacy" />
                          {/* Privacy Policy **
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/e/contact-us" className="link">
                        <span className="sub-nav-text">
                          <IntlMessages id="sidebar.aboutapp.contactus" />
                          {/* Contact Us ***
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/e/faq" className="link">
                        <span className="sub-nav-text">
                          <IntlMessages id="menu.faq" />
                          {/* Contact Us ***
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </Button>
          </li>
          <li className="no-arrow">
            <Button>
              <div className="link" data-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1" onClick={() => toogleSidebar()}>
                <PublicIcon className="icon" />
                <span className="nav-text">
                 {LANGUAGES.find(o=> o.value == locale.locale).label}
                  {/* About Viva Life ***
                </span>
                <div className="collapse" id="collapseExample1">
                  <ul className="sub-menu">
                      {LANGUAGES.filter(o=> o.value !== locale.locale).map((option, key) => (
                      // <option key={key} value={option.value}>{option.label}</option>
                      <li  key={key} onClick={this.onSelectLangauge.bind(this)} data-id={option.value}  className="lang-select">{option.label}</li>
                      ))}
                  </ul>
                </div>
              </div>
            </Button>
          </li>
           */}
          <li className="no-arrow">
            <Button onClick={() => {
              this.handleRequestClose();
              this.props.userSignOut()
            }} >
              <NavLink to="/ea/singin" className="link">
                <ExitToAppIcon className="icon" />
                <span className="nav-text">
                  {/* <IntlMessages id="sidebar.logout" /> */}
                  Log Out
                </span>
              </NavLink>
            </Button>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale } = settings;
  const { authUser, login, profile } = auth;
  return { locale, authUser,  login, profile }
};

export default connect(mapStateToProps, { userSignOut , updateStateOrg, resetDashboard, switchLanguage})(SidenavContent);
