export const API_BASE_URL = 'https://api.vivalife.app/api'
//export const API_BASE_URL = 'http://45.248.158.78:8000/api';

export const SEND_EMAIL_URL = 'https://l4vs7srzr7.execute-api.sa-east-1.amazonaws.com/dev/sendemailfromlambda';

export const APP_IMAGE_URL = 'https://defacto-sync.s3-sa-east-1.amazonaws.com/';
export const APP_IOS_URL = "https://apps.apple.com/app/viva-life-digital-health/id1562997158?l=en";
export const APP_APK_URL ="https://play.google.com/store/apps/details?id=app.vivalife.health";

export const API_KEY = 'AIzaSyBY5bX_bO2rDLnF3NKDk6IKY6WYYWCHJ6M';
export const DEFAULT_PROFILE_PIC = 'user_profiles/default_user.png';

//live server tech@ice account production keys 
export const PUSHER ={
  CLUSTER:'ap2',
  KEY:'d6ca41ffc17e9a50a062'
}

//Test server 
// export const PUSHER ={
//   CLUSTER:'ap2',
//   KEY:'fcef9c98e2bd1a24620c'
// } 

export const AUTH_URLS =  {
  CHECK_EMAIL_EXIST: '/verify/eaemail',
  CHECK_ORG_EXIST: '/verify/orgeaccount',
  USER_SIGNUP: '/register/eaccount',
  DASHBOARD_REGISTER: '/register/dashboard',
  ALL_USER_SIGNUP:"/register/all/eaccount",
  USER_SIGNIN: '/login/enterprise',
  VERIFY_REGISTER_TOKEN: '/verify/token/',
  VERIFY_ACCESS_TOKEN: '/verify/accesstoken/',
  ACCOUNT_ACTIVATE: 'eaccount/activate',
  GET_FP_TOKEN:'/password/get/forgettoken',
  VERIFY_FP_TOKEN:'/password/verify/forgettoken',
  SET_PASSWORD:'/password/setpassword',
  ORG_NAME_VALIDATION:'/validate/organization',
}


export const MESSAGE = {
  GET:"/messages",
  UPDATE:"/messages/viewed",
  ACCEPT:"/message/org/accept/",
  DECLINE:"/message/org/decline/",
  DECLINEDASHBOARD:"enterprise/declineOrg/"
}

export const SUPERADMIN = {
  NEW:"/sa/dashboards/new",
  SENTINVITATION:"/sa/dashboard/sendInvitation/",
  PROCESSED:"/sa/dashboards/get",
  RESEND:"/sa/dashboard/resendInvitation/",
  DELETE:"/sa/dashboards/delete/",
  DELETE_ACCESS_TOKEN:'/sa/accesstoken/delete/',
  CHECKSUPERADMIN:'validate/superadmin/email',
  INACTIVEUSERS:'/sa/get/inactiveappusers',
  SETACTIVE:'/sa/set/activeappuser/',
  GLOBLEPN:'/sa/global/push/segment/notification',
  GETPNS:'/sa/get/globalnotification'
}

export const PROFILE_URLS =  {
  UPDATE_PROFILE_INFO:'/enterprise/update',
  ADD_ORG: '/enterprise/addOrg',
  REMOVE_ORG:'/enterprise/removeOrg/',
  REMOVE_ORGANIZATION:'/org/removeOrgOwner/',
  DELETE_ACCOUNT:'/enterprise/delete',
  GET:'/enterprise/get',
  STEP0:'/enterprise/email/check',
  STEP1:'/enterprise/email/update',
  STEP2:'/enterprise/email/verify',
}

export const STATIC_PAGES_URLS={
   ABOUT_US:'/pages/eaabout/',
   ABOUT_US_APP:'/pages/about/',
   TERMS:'/pages/terms/',
   PRIVACY:'/pages/privacy/',
   PRIVACY_WEB:'/pages/privacy_web/',
   TERMS_WEB:'/pages/terms_web/',
   FAQ_WEB:'/pages/faq/',
   FAQ:'/pages/faq_app/',
   SUPPORT:'/support',
   GUIDE_WEB:'/pages/guide/'
 }

export const DASBOARD_PAGE_URLS={
  ANALYTICS:"/analytics/",
  MEMBERS_LIST:"/members/",
  DELETE_MEMEBERS:"/members/delete/"
}


export const LANGUAGES = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Bahasa Melayu",
    value: "ms",
  },
  {
    label: "中文简体",
    value: "zhs",
  },
  {
    label: "中文繁體",
    value: "zht",
  }
];

export const SETTINGS = {
  ORG:"/org/",

  ADD_USER:"/addUser",
  REMOVE_USER:"/removeUser",
  RESEND:"/resend",
  DELETE_USER:"/deleteUser",

  REMOVE_ORG:'/enterprise/removeOrg/',
  REMOVE_ORGANIZATION:'/org/removeOrgOther/',

  USERS:"/users",
}

export const ROLES = {
  en:[
  {
    "id":1,
    "name":"Owner"
  },
  {
    "id":2,
    "name":"Admin"
  },
  {
    "id":3,
    "name":"Overview"
  }
],
ms:[
  {
    "id":1,
    "name":"Pemilik"
  },
  {
    "id":2,
    "name":"Pentadbir"
  },
  {
    "id":3,
    "name":"Gambaran"
  }
],
zhs:[
  {
    "id":1,
    "name":"所有权人"
  },
  {
    "id":2,
    "name":"管理员"
  },
  {
    "id":3,
    "name":"全览"
  }
],
  zht:[
    {
      "id":1,
      "name":"所有權人"
    },
    {
      "id":2,
      "name":"管理員"
    },
    {
      "id":3,
      "name":"全覽"
    }
  ]

}

export const STATUS = {
  PENDING:"pending",
  ACCEPTED:"accepted",
  OWNER:'owner',
  ADMIN:'admin',
  OVERVIEWR:'viewer',
  CONTACT:'contact'
}

export const STRINGS = {
  en:{
    email:"Email address",
    organisationId:"Organization ID",
    firstName:"First name",
    lastName:"Last name",
    password:"Password",
    negative:"Negative",
    positive:"Positive",
    invalid:"Invalid",
    inactive:"Inactive",
    exportTOCSV:"Export to CSV",
    search:"Search",
    select:"Select",
    pending:"Pending",
    verificationCode:'Verification code',
    accountTechnical:"Account and technical issues",
    businessOpportunities:"Business opportunities",
    feedback:"Feedback and testimonies",
    other:"Other",
    message:"Message",
    confirmpassword:"Confirm",
    newpassword:"New password",
    date:"Date",
    category:"Category",
    result:"Result",
    attachment:"Attachment",
    product:"Product",
    serialNumner:"Serial number",
    organisation:"Organization",
    supplier:"Supplier",
    errorsPassword: "Password must be between 8 and 50 characters long, and include at least one number, and one uppercase and lowercase letter",
  },
  ms:{
    email:"Alamat e-mel akaun",
    organisationId:"ID organisasi",
    firstName:"Nama pertama",
    lastName:"Nama akhir",
    password:"Kata laluan",
    negative:"Negatif",
    positive:"Positif",
    invalid:"Tidak Sah",
    inactive:"Tidak aktif",
    exportTOCSV:"Eksport ke CSV",
    search:"Search",
    select:"Pilih",
    pending:"Belum selesai",
    verificationCode:'Kod pengesahan',
    accountTechnical:"Isu akaun dan teknikal",
    businessOpportunities:"Peluang Bisnes",
    feedback:"Maklum balas dan testimoni",
    other:"Yang lain",
    message:"Mesej",
    confirmpassword:"Pengesahan",
    newpassword:"Kata laluan baru",
    date:"Tarikh",
    category:"Kategori",
    result:"Keputusan",
    attachment:"Lampiran",
    product:"Produk",
    serialNumner:"Nombor siri",
    organisation:"Organisasi",
    supplier:"Supplier",
    errorsPassword: "Kata laluan mestilah antara 8 dan 50 aksara panjang, dan termasuk sekurang-kurangnya satu nombor, dan satu huruf besar dan huruf kecil",
  },
  zhs:{
    email:"邮箱",
    organisationId:"机构账号",
    firstName:"名字",
    lastName:"姓",
    password:"密码",
    negative:"阴性",
    positive:"阳性",
    invalid:"无效",
    inactive:"待用",
    exportTOCSV:"导出为CSV文件",
    search:"Search",
    select:"选择",
    pending:"待处理",
    verificationCode:'验证码',
    accountTechnical:"账号和技术问题",
    businessOpportunities:"商业机会",
    feedback:"反馈和证词",
    other:"其他",
    message:"Message",
    confirmpassword:"确认",
    newpassword:"新的密码",
    date:"日期",
    category:"类型",
    result:"结果",
    attachment:"附件",
    product:"产品",
    serialNumner:"序列号",
    organisation:"Organization",
    supplier:"Supplier",
    errorsPassword: "密碼長度必須在8到50個字符之間，並且至少包括一個數字、一個大寫字母和小寫字母",
  },
  zht:{
    email:"郵箱",
    organisationId:"機構賬號",
    firstName:"名字",
    lastName:"姓",
    password:"密碼",
    negative:"陰性",
    positive:"陽性",
    invalid:"無效",
    inactive:"待用",
    exportTOCSV:"導出為CSV文件",
    search:"Search",
    select:"選擇",
    pending:"待處理",
    verificationCode:'驗證碼',
    accountTechnical:"賬號和技術問題",
    businessOpportunities:"商業機會",
    feedback:"反饋和證詞",
    other:"其他",
    message:"Message",
    confirmpassword:"確認",
    newpassword:"新的密碼",
    date:"日期",
    category:"類型",
    result:"結果",
    attachment:"附件",
    product:"產品",
    serialNumner:"序列號",
    organisation:"Organization",
    supplier:"Supplier",
    errorsPassword: "密碼長度必須在8到50個字符之間，並且至少包括一個數字、一個大寫字母和小寫字母",
  }
}




export const IMAGE_FOLDER = {
  APP_IMAGES:'AppImages/'
}
export const EMAIL_SENDER = 'surjeet@yopmail.com';  
export const ENCRYPTION_KEYS = {
  CRYPTOJS_SECRETKEY: "69845lkhj21335",
};


export const ACCESS_TOKEN_EXPIRE_TIME = "30"; // in Minutes
export const VERIFICATION_CODE_EXPIRE_TIME = "480" // in Minutes


export const REGEX_VALID_URL = new RegExp(
  "^" +
  // protocol identifier
  "(?:(?:https?|ftp)://)" +
  // user:pass authentication
  "(?:\\S+(?::\\S*)?@)?" +
  "(?:" +
  // IP address exclusion
  // private & local networks
  "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
  "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
  "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
  // IP address dotted notation octets
  // excludes loopback network 0.0.0.0
  // excludes reserved space >= 224.0.0.0
  // excludes network & broacast addresses
  // (first & last IP address of each class)
  "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
  "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
  "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
  "|" +
  // host name
  "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
  // domain name
  "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
  // TLD identifier
  "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
  // TLD may end with dot
  "\\.?" +
  ")" +
  // port number
  "(?::\\d{2,5})?" +
  // resource path
  "(?:[/?#]\\S*)?" +
  "$", "i"
);



