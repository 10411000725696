import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import SidenavContent from './SidenavContent';
import UserInfo from 'components/UserInfo';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from 'constants/ActionTypes';
import {toggleCollapsedNav, updateWindowWidth} from 'actions/Setting';
import Logo from "./../../assets/images/logo.png";
import MenuIcon from "./../../assets/images/menu_icon.png";
import CloseIcon from "./../../assets/images/close_icon.png";

class SideNav extends React.PureComponent {

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });
  }

  render() {
    const {navCollapsed, drawerType, width, navigationStyle} = this.props;
    let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
    let type = 'permanent';
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
      type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = '';
      type = 'temporary';
    }

    const toogleSidebar = () => {
      var ele = document.body;
      ele.classList.toggle("sideMenu");
      ele.classList.toggle("change");
  
      // let element = document.getElementById('collapseExample');
      // ReactDOM.findDOMNode(element).classList.remove("show");
    };
    const removeSidebar = () => {
      var ele = document.body;
      ele.classList.remove("sideMenu");
    };
    return (
      <div id="sidebar" className="app-sidebar">
        <Drawer className="app-sidebar-content"
                variant={type}
                open={type.includes('temporary') ? navCollapsed : true}
                onClose={this.onToggleCollapsedNav}
                classes={{
                  paper: 'side-nav',
                }}
        >
          
            <Link to="/index"  onClick={() => removeSidebar()}>  <img src={Logo} alt="Logo" className="logo" /></Link>
         
           <button
              className="toogle_btn"
              type="button"
              onClick={() => toogleSidebar()}>
               <img src={MenuIcon} alt="MenuIcon" className="burgure_menu " />
               <img src={CloseIcon} alt="CloseIcon" className="close_menu " />
           </button>
          <SidenavContent/>
          <UserInfo/>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navCollapsed, drawerType, width, navigationStyle} = settings;
  return {navCollapsed, drawerType, width, navigationStyle}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, updateWindowWidth})(SideNav));

 