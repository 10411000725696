import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/IntlMessages";
import Instructions from "../popups/instructions";
import {AUTH_URLS } from "../../../../../constants/AppConst";
import { httpClient } from '../../../../../appUtility/Api';
import Popup from "../../../../../containers/Popups/ForgetPasswordVerification";
import { setSuperFeatures} from 'actions/Setting'; 


class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      instPopup:false,
      error:'',
      forgetToken:'',
      activateMessage:'',
      verifyForgetPassword:false
    };
  }

  closeInstructions = ()=> {
    this.setState({instPopup:false});
    this.props.setSuperFeatures(1);
  }

  componentDidMount(){
    
    setTimeout(() => {
      const {passwordUpdate} = this.props.superFeatures;     
      if(passwordUpdate == 0){
        this.setState({instPopup:true});
      }
    }, 1000);
  
  }

  sendVerificationCode = async () => {
    let postdata = { "email":this.props.authUser.email};
    
    // this.setState({
    //   deleteAccountpop: false,
    //   //verifyForgetPassword:true,
    //   //email:this.props.authUser.email,
    // });

    try {
    var res = await httpClient
      .post(AUTH_URLS.GET_FP_TOKEN, postdata)
      .then((res) => { return res.data; })
      .catch((error) => {
        this.setState({error: error.response.data.error,  isLoading: false})
        return {status:'fail'};
      });
      } catch (error) {
        return error.response.data.status;
      
    }
    
      if (res.status === "success") {
          this.setState({ instPopup: false, verifyForgetPassword: true , forgetToken:res.token, activateMessage:res.message});
      }

    }


  render() {
    const { selectedEcho } = this.props;
    const { instPopup,forgetToken,activateMessage,verifyForgetPassword } = this.state;
    return (
      <div className="account-page">
        <div className="app-title">
          <h1>
            {/* <IntlMessages id="vivalife.ea" /> */}
            Enterprise Account
          </h1>
          <h1 className="title-super-admin">Super admin</h1>
        </div>
        
        {instPopup && <Instructions  formdata = {{ showModal: instPopup}}  closeModal={this.closeInstructions.bind(this)}  forgetPassword={this.sendVerificationCode.bind(this)} />}
        {verifyForgetPassword && <Popup  formdata = {{ showModal: verifyForgetPassword ,forgetToken:forgetToken,activateMessage:activateMessage}} />}
      </div>
    );
  }
}

const mapStateToProps = ({ auth,settings }) => {
   const { authUser } = auth;
   const {superFeatures}= settings;
   return { authUser,superFeatures };
};

export default connect(mapStateToProps, {
  setSuperFeatures
})(Header);
