import appLocaleData from 'react-intl/locale-data/zh';
import zhsMessages from '../locales/zh_Hans.json';

const ZHsLang = {
  messages: {
    ...zhsMessages
  },
  locale: 'en-ZHS',
  data: appLocaleData
};
export default ZHsLang;
