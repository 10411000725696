import React from "react";
import { Link, Redirect } from "react-router-dom";
import {  connect } from "react-redux";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik } from "formik";
import * as Yup from "yup";
import { httpClient } from "../appUtility/Api";
import { hideMessage, showAuthLoader } from "actions/Auth";
import { AUTH_URLS, STRINGS } from "constants/AppConst";
import Logo from "../assets/images/logo2.png";
import Header from "./CommonHeader/header";
import EmailIcon from '@material-ui/icons/Email';
import Popup from "./Popups/ForgetPasswordVerification";

const validationSchema = Yup.object({
  email: Yup.string()
  .required(<IntlMessages id="errors.required" />)
  .email(<IntlMessages id="errors.emailInvalid" />),
});

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      showModal:false,
      activateMessage:'',
      forgetToken:'',
      email:'',
      error:''
    };
  }

  componentDidUpdate() {
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }

  sendVerificationCode = async (email) => {
    let postdata = { "email":email};
    
    this.setState({
      isLoading: true,
      email:email,
    });

    try {
    var res = await httpClient
      .post(AUTH_URLS.GET_FP_TOKEN, postdata)
      .then((res) => { return res.data; })
      .catch((error) => {
        console.log(error.response.data);
        //this.state.error = error.response.data.error;
        this.setState({error: error.response.data.error,  isLoading: false})
        return {status:'fail'};
      });
      } catch (error) {
        return error.response.data.status;
      //ToastsStore.error(error);
    }
    
      if (res.status === "success") {
          this.setState({ showModal: true , error:'',  isLoading: false, forgetToken:res.token, activateMessage:res.message});
      }

    }

  handleChange = (e)=>{
    this.setState({email:e.target.value});
  }

  render() {
    const { isLoading, error } = this.state;
    const { login, locale} = this.props;

    if (login === true) {
      return ( <Redirect to={'/ea/dashboard/'}/> );
    }

    return (
        <div className="w-100 bg-gray m-h-100 signin-page">
        <Header/>
        <div className="container">
        
            <div className="row align-items-center mt-4">
                <div className="col-md-5">
                  <div className="left-section">
                    <div className="logo">
                      <img src={Logo} alt="Logo" className="logo" />
                    </div>
                    <div className="already-text">
                      <span className="d-inline-block mr-2"><IntlMessages id="vivalife.donthaveaccount" /></span>
                      <Link to="/ea/register"><IntlMessages id="vivalife.registernow" /></Link>
                    </div>
                  </div>  
                </div> 
                <div className="col-md-7">
                    <div className="app-login-content">
                  <div className="app-login-header mb-4">
                    <h1>
                      <IntlMessages id="vivalife.forgetpassword" />
                    </h1>
                  </div>

                 <div className="app-login-form">
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      this.sendVerificationCode(values.email);
                    }}

                  >
                    {({ handleSubmit, handleChange, values, errors }) => (
                      <form onSubmit={handleSubmit}>
                        <fieldset>
                        <div className="form-group">
                          <label>{<IntlMessages id="form.email" />}<span className="text-red">*</span></label>
                          <input
                            label={<IntlMessages id="form.email" />}
                            placeholder={STRINGS[locale.locale].email}
                            onBlur={handleChange}
                            defaultValue={values.email}
                            name="email"
                            margin="normal"
                            className="field form-control"
                          />
                           <EmailIcon className="icon"/>
                          <p className="error">
                          {errors.email}
                          {error.length > 0 && error}
                          </p>
                          </div>
                          <div className="mb-3 d-flex align-items-center justify-content-between">
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              <IntlMessages id="form.submit" />
                            </Button>
                          </div>
                        </fieldset>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
                </div>
             </div>
         </div>
        {isLoading && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}

        {this.state.showModal && (
          <Popup formdata = {this.state} />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { loader, alertMessage, showMessage, authUser, login } = auth;
  const { locale } = settings;
  return { loader, alertMessage, showMessage, authUser, login, locale};
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
})(ForgotPassword);
