import appLocaleData from 'react-intl/locale-data/ms';
import msMessages from '../locales/mys.json';

const MsLang = {
  messages: {
    ...msMessages
  },
  locale: 'en-MS',
  data: appLocaleData
};
export default MsLang;
