import React ,{ useState, useEffect }  from 'react';
import { httpClient } from '../../../../../../appUtility/Api';
import { STATIC_PAGES_URLS } from '../../../../../../constants/AppConst';
import Parser from 'html-react-parser';
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "../../../../../../containers/StaticHeader/header";
import {switchLanguage} from '../../../../../../actions/Setting';
import { connect } from "react-redux";

const AboutUs = ({match}) => {
 
  const params = new URLSearchParams(window.location.search)
  //const lang =params.has('lang')?params.get('lang'):'en';
  const [aboutUsBody, setAboutUsBody] = React.useState('');
  const [aboutUsTitle, setAboutUsTitle] = React.useState('');

  const language = localStorage.getItem("language");
  var lang='en';

  if(language==='zht'){
    lang = 'zhHant';
  }else if(language==='zhs'){
    lang = 'zhHans';
  }else if(language==='ms'){
    lang = 'ms';
  }else if(language==='en'){
    lang = 'en';
  }
  

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
  
    httpClient.get(STATIC_PAGES_URLS.ABOUT_US+ lang )
   .then(results => results.data)
   .then(data => {
      setAboutUsBody(data.detail.body);
      
      setAboutUsTitle(data.detail.title)
   });
}, []); // <-- Have to pass in [] here!
  return (
    <div className="bg-gray w-100 m-h-100"> 
      <Header/>
      {aboutUsBody !="" && Parser(aboutUsBody)}
      {aboutUsBody =="" &&(
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
    </div>
  );
};
const mapStateToProps = ({ settings }) => {
  const {locale}=settings.locale;
  
  return { locale };
  };

export default connect(mapStateToProps, {
    switchLanguage
  })(AboutUs);

