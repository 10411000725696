import React from 'react';
import Modal from "react-modal";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import IntlMessages from 'util/IntlMessages';
//import { httpClient } from '../../../../../../appUtility/Api';
//import { SETTINGS, STATUS} from '../../../../../../constants/AppConst';



class Instructions extends React.Component {

  constructor() {
    super();
    this.state = {
      
    };

  }

  componentDidMount= ()=>{
    Modal.setAppElement('body');
  }
  
  render() {
    
    return (
      <Modal
        isOpen={this.props.formdata.showModal}
        contentLabel="Register Verification"
        className="register-modal"
      >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" >

              <div className="modal-header">
                  <button type="button" class="close" onClick={()=>{this.props.closeModal()}} >
                      <span  className="sub-nav-text" aria-hidden="true">&times;</span>
                  </button>
                {/* <button type="button" className="close" data-dismiss="modal">&times;</button> */}
                {/* <h4 className="modal-title">Success!</h4> */}
              </div>

              <div className="modal-body p-0">
                <p className="mt-3 mb-4 text-center">
                  Please udpate your password
                </p>
              </div>

              <div className="modal-footer text-center">
                <button className="btn btn-secondary" onClick={()=>{this.props.closeModal()}}><IntlMessages id="vivalife.close" /></button>

                <button className="btn btn-primary bg-purple" onClick={()=>{this.props.forgetPassword()}}><IntlMessages id="Proceed" /></button>
              </div>
            </div>
     

          {/* {this.state.isLoading && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )} */}

        </div>
      </Modal>
    );
  }

}


const mapStateToProps = ({ auth, dashboard}) => {
  const { authUser } = auth;
  const { orgnization } = dashboard;
  return { authUser, orgnization };
};

export default connect(mapStateToProps)(Instructions);