import React from 'react';
import Modal from "react-modal";
import IntlMessages from 'util/IntlMessages';
import { Link } from 'react-router-dom';




class RegisterSuccess extends React.Component {

  constructor() {
    super();
    this.state = {
      modal: {
        code: '',
        token: ''
      },
      error: '',
      successMessage: '',
      success: false,
      isInValidForm: true,
      code:''
    };

  }


  render() {
    const { locale } = this.props;
    const { error, successMessage } = this.state;
    return (
      <Modal
        isOpen={this.props.formdata.showModal}
        contentLabel="Register Verification"
        className="register-modal"
      >
        <div className="modal-dialog modal-dialog-centered">

        

        
            <div className="modal-content" >

              <div className="modal-header">
                    <button className="close" >
                       <Link to="/ea/index">
                         <span>&times;</span>
                       </Link>
                    </button>
              </div>

              <div className="modal-body p-0">
                <p className="mt-3 mb-4 text-center">
                  <IntlMessages id="vivalife.invitationExpired" />
                </p>
              </div>

            </div>
        

        </div>
      </Modal>
    );
  }

}

const mapStateToProps = ({ auth, settings }) => {
  const { locale } = settings;
  return { locale};
};

export default  RegisterSuccess;