import {
    FETCH_ANALYTICS_DATA,UPDATE_DATE_RANGE,UPDATE_ACTIVE_DAYS,FETCH_MEMBERS_DATA, UPDATE_SELECTED_ORG, RESET_DASHBOARD, UPDATE_MY_ORGANIZATIONS
  } from "constants/ActionTypes";
  const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    MembersTabData: {
        activeMembers: {
          doseBooster: 0,
          doseFirst: 0,
          invalid: 0,
          negative: 0,
          positive: 0,
          total: 0,
        },
        allMembers: {
          doseBooster: 0,
          doseFirst: 0,
          invalid: 0,
          negative: 0,
          positive: 0,
          total: 0,
        },
        inactiveMembers: {
          doseBooster: 0,
          doseFirst: 0,
          invalid: 0,
          negative: 0,
          positive: 0,
          total: 0,
        },
      },
    TestsTabData: {
          allTests: {
            doseBooster: 0,
            doseFirst: 0,
            invalid: 0,
            negative: 0,
            positive: 0,
            total: 0,
          },
        selectedTests: {
          doseBooster: 0,
          doseFirst: 0,
          invalid: 0,
          negative: 0,
          positive: 0,
          total: 0,
        },
    },
    membersData:{current_page:0,data:[],per_page:50,to:0,total:0},
    days:'0',
    from:'',
    to:'',
    needRefresh:false,
    activeDays:"7",
  //  membersData:[],
    orgnization:localStorage.getItem('orgnization') ? JSON.parse(localStorage.getItem('orgnization')) : {},
    myOrganizations:[]
  };
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case FETCH_ANALYTICS_DATA: {
        return {
          ...state,
          MembersTabData: action.payload.membertab,
          TestsTabData:action.payload.teststab
        }
      }
      case UPDATE_DATE_RANGE: {
        return {
          ...state,
          days: action.payload.days,
          from:action.payload.from,
          to:action.payload.to
        }
      }
      case UPDATE_ACTIVE_DAYS: {
        return {
          ...state,
          activeDays: action.payload.activeDays,
        }
      }
      case FETCH_MEMBERS_DATA:{
        return {
            ...state,
            membersData: {current_page:action.payload.current_page,
              data:action.payload.data,per_page:action.payload.per_page,
              to:action.to,total:action.payload.total} ,
          
          }
      }
      case UPDATE_SELECTED_ORG:{
        return {
            ...state,
            orgnization: action.payload,
          }
      }
      case UPDATE_MY_ORGANIZATIONS:{
        return {
          ...state,
          myOrganizations: action.payload,
        }
      }
      case RESET_DASHBOARD:{
        return INIT_STATE;
      }
      default:
        return state;
    }
  }

 