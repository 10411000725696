import React from 'react';
import { Link } from 'react-router-dom';
import {switchLanguage} from '../../actions/Setting';
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import BurgerMenu from "./menu";
import ApplyPop from "../../containers/Popups/Register";

class Menu extends React.Component {
  
  constructor() {
    super();
    this.state = {
      menu: false,
      applyPopup:false,
      showModal:false
    };
  }

  componentDidMount() {
    const language = localStorage.getItem("language");
    if(!language){
      localStorage.setItem('language', this.props.locale);
    }
  }

  togglefn2() {
    this.setState({
      menu: !this.state.menu
    });
  }

  ToggelRegister(){
    this.setState({
      applyPopup: !this.state.applyPopup,
      showModal: !this.state.showModal
    });
  }
  
  render() {
    const {
      locale,
      login
    } = this.props;
    //const {first_name, last_name}= this.props.authUser;
    //console.log('Locale',this.props);
    const { applyPopup } = this.state;
    return (
          <div className="col-8">
            <div className="collapse-nav">
              <div className="d-flex align-items-center justify-content-end"> 
                <span className="user-name mr-3">
                
                   {!login && this.props.data.isHome && ( <IntlMessages id="vivalife.ea" /> )}
                   {login && this.props.data.isHome  && (<Link to="/ea/account"> { this.props.authUser.first_name} { this.props.authUser.last_name}  </Link> )}
                   { this.props.data.isHome && ( <button className="btn-apply btn" onClick={()=>this.ToggelRegister()}><IntlMessages id="vivalife.applyNow" /> </button > )}
                </span>
                <BurgerMenu  data={{isHome:this.props.data.isHome}} />
              </div>             
            </div>
            {applyPopup && <ApplyPop formdata = {this.state} closeModal={this.ToggelRegister.bind(this)}/>}
          </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const {locale}=settings.locale;
  const login = auth.login;
  const {authUser} = auth;
  return { locale, login ,authUser };
  };
  
  export default connect(mapStateToProps, {
    switchLanguage
  })(Menu);