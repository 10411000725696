import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {switchLanguage} from 'actions/Setting';
import {fetchAnalyticsData,fetchMembersData, updateStateOrg, updateMyOrganizations} from 'actions/DashboardAction';
import Select,{components} from 'react-select';
import { DASBOARD_PAGE_URLS, PROFILE_URLS } from "../../constants/AppConst";
import {httpClient} from "../../../src/appUtility/Api";
import IntlMessages from "util/IntlMessages";
import {userSignOut, userSignIn, updateProfileInfo} from 'actions/Auth';


const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      <span> <IntlMessages id="vivalife.noOrganisations" /></span> 
    </components.NoOptionsMessage>
  );
};

class Header extends React.Component {
  
  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      optionsdata :  [
        
      ],
      org_name:'',
      getDataStatus :false,
      userData:{},
      success:false
    };
  }

  

  onSelectOrganization=(e)=>{
    
    let obj={orgId:e.value.orgId,relKey:e.value.relKey,rolename:e.value.name, org_name:e.value.org_name, org_id:e.value.org_id};
    this.props.updateStateOrg(obj);
    localStorage.setItem("orgnization", JSON.stringify(obj));   
    setTimeout(() => {
      this.fetchDashbaord();
    }, 500);

  }

  componentDidMount = ()=>{
        this.getData();
        window.channel.bind('accountUpdate', this.getData);
        window.channel.bind('accountUpdate', this.fetchAnalytics);
  }

  componentWillUnmount =()=>{
    window.channel.unbind();
  }

  fetchAnalytics = ()=>{
    this.fetchDashbaord();
  }

  getData =()=> {

    const body= {};
    try{
      httpClient
      .get(
        PROFILE_URLS.GET,
        body
      )
      .then((res) => {
        if (res.status === 200) {
          const userData =res.data.data;
          const token=  JSON.parse(localStorage.getItem('tokens'));
          userData.token = token;
          localStorage.setItem("login_user", JSON.stringify(userData));
          this.props.updateMyOrganizations(userData.organizations);
          this.props.updateProfileInfo({
            full_name:"",
            unread:userData.unread,
            email:null,
            newOrg:userData.newOrgs
          });
          this.updateCurrentOrg(userData.organizations);
        } 
      
      })
      .catch((err) => {
        // if(err.response.data.message == 'Unauthenticated.'){
        //     this.props.userSignOut();
        // }
        console.log("here in error");
      });
    } catch (error) {
      console.log("in catch extrnal");
    }
    
  }

  
   
  updateCurrentOrg = (Orgs)=>{

        var orgData=Orgs.map((item)=>{
          return {label:item.org_name+' - '+item.org_id,value:{orgId:item.id,relKey:item.relation,name:item.name,org_name:item.org_name,org_id:item.org_id}}
        });

        this.setState({optionsdata:orgData,success:false});

        if(orgData.length>0 && Object.keys(this.props.orgnization).length === 0 )
        {
          let obj={orgId:orgData[0].value.orgId,relKey:orgData[0].value.relKey,rolename:orgData[0].value.name,org_name:orgData[0].value.org_name,org_id:orgData[0].value.org_id};
          localStorage.setItem("orgnization", JSON.stringify(obj));
          this.props.updateStateOrg(obj);
        }
   

  }

  fetchDashbaord(){
  
    const {orgnization} = this.props;
    //this.props.switchOrganization({orgId:orgData[0].value.orgId,relKey:orgData[0].value.relKey,orgName:orgData[0].label})
    try {
      let body = {
        days: 0
      };
      if(this.props.days!='other' && this.props.days!='all')
      {
        body={
          days:this.props.days
        }
      }
      else if(this.props.days=='other')
      {
        body={
          from:this.props.from,
          to:this.props.to
        }

      }
      else
      {
        body = { };
      }

       Object.assign(body, {setActiveDays:this.props.activeDays, 'timeStamp': Math.floor(Date.now()/1000)});

      httpClient
        .post(
          DASBOARD_PAGE_URLS.ANALYTICS +
          orgnization.relKey,
          body
        )
        .then((res) => {
          if (res.status === 200) {
            const memberTab = res.data.detail.membertab;
            const testsTab = res.data.detail.teststab;
            this.props.fetchAnalyticsData(res.data.detail);
            this.fetchMembersList();
          } else {
            console.log("not 200");
          }
          this.setState({ isLoading: false });
        })
        .catch((err) => {
        
          if(err.response.data.message == 'Unauthenticated.'){
            this.props.userSignOut();
          }
          console.log("here in error");
        });
    } catch (error) {
      this.setState({ isLoading: false });    
    }
  }

  fetchMembersList(){
    const {orgnization} = this.props;
    try {
       let body = {
        per_page:this.props.membersData.per_page,
        search:'',
        sorting_column:'added_on',
        sorting_by:'desc'
        };
      httpClient
        .post(
          DASBOARD_PAGE_URLS.MEMBERS_LIST +
          orgnization.relKey,
          body
        )
        .then((res) => {
          if (res.status === 200) {
            //this.props.fetchMembersData(res.data.detail);
            this.props.fetchMembersData(res.data.data);
          } else {
            //ToastsStore.error("Something went wrong");
          }
         
        })
        .catch((err) => {
         // ToastsStore.error("Something went wrong");
        });
    } catch (error) {
      //ToastsStore.error(error);
    }
  }

  render() {
    
    const {orgnization}=this.props;
   
    const { optionsdata } = this.state;
 
    return (
      <div className="header">
          <div className="select_organisation">
            <div className="container-fluid">
              <div className="row align-items-center">
                 <div className="col-xl-2 col-lg-3 text-lg-center">
                   <label>
                     <IntlMessages id="header.selectlabel" />
                     {/* Select Organisation */}
                    </label>
                 </div>
                 <div className="col-xl-10 col-lg-9">
                  <Select  onChange={this.onSelectOrganization} placeholder={<div><IntlMessages id="options.select" /></div>} components={{ NoOptionsMessage }}
                 value= {optionsdata.find(option => option.value.orgId === orgnization.orgId)}
                   className="select"  options={optionsdata} />    
                 </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}


const mapStateToProps = ({settings,auth,dashboard}) => {
  const {organizations,channel}=auth.authUser!=undefined ?auth.authUser:[];
  const{days, activeDays,to,from,membersData, orgnization}=dashboard;
  const {drawerType, locale, navigationStyle, horizontalNavPosition,orgId,relKey,orgName} = settings;
  return {channel,drawerType, locale, navigationStyle, horizontalNavPosition,organizations,days,activeDays,to,from,orgId,relKey,orgName, orgnization, membersData}
};

export default withRouter(connect(mapStateToProps, {fetchMembersData,updateMyOrganizations, updateStateOrg, switchLanguage,fetchAnalyticsData,userSignOut, userSignIn, updateProfileInfo})(Header));