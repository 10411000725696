import React from 'react';
import Modal from "react-modal";
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import { httpClient } from '../../appUtility/Api';
import { AUTH_URLS,SUPERADMIN } from '../../constants/AppConst';
import { Link } from 'react-router-dom';
import {  userSignIn } from '../../actions/Auth';
import OtpInput from 'react-otp-input';
import { Formik } from "formik";
import * as Yup from "yup";
import EmailIcon from '@material-ui/icons/Email';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';

const validationSchema = Yup.object({
  email: Yup.string()
    .required(<IntlMessages id="errors.required" />)
    .email(<IntlMessages id="errors.emailInvalid" />),
  org_name: Yup.string()
    .required(<IntlMessages id="errors.required" />),
  supplier: Yup.string()
    .required(<IntlMessages id="errors.required" />)
});



class JoinNOw extends React.Component {

  constructor() {
    super();
    this.state = {
      error: '',
      successMessage: '',
      registerSuccessMsg: '',
      success: false,
      isInValidForm: true,
      apiOrgError:'',
      apiEmailError:'',
      responseError:''
    };

  }
  
  handleUserSignUp = async (email, org_name, supplier) => {
    
    try {
      const signUpResponse = await this.createUserWithEmailPasswordRequest(email, org_name, supplier);
      if (signUpResponse.status === 200) {
        this.setState({ 
          successMessage: signUpResponse.data.message, 
          success: true}
          );
      }
      else if (signUpResponse.status === "fail") {
        // this.setState({
        //   isLoading: false,
        //   showModal: false
        // });
        console.log("fail");
      }

    } catch (error) {
      if (error.response) {

        if(Array.isArray(error.response.data.error)){
          this.setState({responseError:error.response.data.error[0]});
        }else{
          this.setState({responseError:error.response.data.error});
        }
      
      } else {
        // this.setState({
        //   isLoading: false,
        //   showModal: false
        // });
        console.log("catch else");
       
      }
    }
  };

  createUserWithEmailPasswordRequest = async (email, org_name, supplier) => {
    const language = localStorage.getItem("language");
    var xlocal='en';

    if(language==='zht'){
      xlocal = 'zhHant';
    }else if(language==='zhs'){
      xlocal = 'zhHans';
    }else if(language==='ms'){
      xlocal = 'ms';
    }else if(language==='en'){
      xlocal = 'en';
    }

    return await httpClient.post(AUTH_URLS.DASHBOARD_REGISTER, {
      org_name: org_name,
      distributor_name: supplier,
      email: email,
      language:xlocal
    });
  };

  verifyOrgName =  (orgName) => {
    let postdata = {org_name:orgName};
     httpClient
      .post(AUTH_URLS.ORG_NAME_VALIDATION, postdata)
      .then((res) => { 
        this.setState({responseError:""});
      })
      .catch((error) => {
  
        if(Array.isArray(error.response.data.errors)){
          this.setState({apiOrgError:error.response.data.errors[0]});
        }else{
          this.setState({apiOrgError:error.response.data.error});
        }
      });
  };


  checkSuperAdmin =  (email) => {
    let postdata = { "email": email };
     httpClient
      .post(SUPERADMIN.CHECKSUPERADMIN, postdata)
      .then((res) => { 
        //console.log(res.data.no_of_orgs);
        
        this.setState({apiEmailError:''});
      })
      .catch((error) => {
        if(Array.isArray(error.response.data.error)){
          this.setState({apiEmailError:error.response.data.error[0]});
        }else{
          this.setState({apiEmailError:error.response.data.error});
        }
      });
  };

  render() {
    const { locale } = this.props;
    const { error, successMessage,isLoading, responseError ,apiEmailError, apiOrgError} = this.state;
    return (
      <Modal
        isOpen={this.props.formdata.showModal}
        contentLabel="Register Verification"
        className="register-modal joinNow-form"
      >
        <div className="modal-dialog modal-dialog-centered">

          {!this.state.success && (
            <div className="modal-content" >
              
              <div className="">
              <button type="button" class="close" onClick={()=>{this.props.closeModal()}} >
                      <span aria-hidden="true">&times;</span>
                  </button>
                <h2><IntlMessages id="vivalife.applyNewDashboard" /> </h2>
              <div className="app-login-content">
                <div className="app-login-header text-left" id="app-register-header">
                  {/* <h1><IntlMessages id="vivalife.register" /></h1> */}             
                  <span ><IntlMessages id="vivalife.dashboardSetup" /></span>
                </div>
                <div className="app-login-form" id="register-form"> 
                <Formik initialValues={{ email:"", org_name:"",supplier:"" }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      this.handleUserSignUp(values.email, values.org_name, values.supplier);
                      //resetForm();
                    }}
                >

                    {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                      
                    <div className="form-group">
                      <label><IntlMessages id="form.organisationDashboardName" /><span className="text-red">*</span></label>
                      <input
                        type="text"
                        //placeholder={STRINGS[locale.locale].organisation}
                        onChange={e => {handleChange(e);
                          this.setState({apiOrgError:''})
                        }}
                        onBlur={e => {
                          handleBlur(e);
                          //if(e.target.value && !errors.org_name && role == 1 ){this.checkOrgExistOrNot(e.target.value, values.email)};
                          if(e.target.value != ""){
                            this.verifyOrgName(e.target.value);
                          }
                        }}
                        defaultValue={values.org_name}
                        name="org_name"
                        margin="normal"
                        className="field form-control"
                        noValidate
                      />
                       {( this.state.isMoreOrgs && <div class="add-org" >
                              <i class="fas fa-plus" ></i>
                        </div> )}
                      <GroupWorkIcon className="icon" />
                      <p className="error">
                          {errors.org_name && touched.org_name ? (
                                <span>{errors.org_name}</span>
                          ) : null}
                           {apiOrgError}
                      </p>
                    </div>

                    <div className="form-group">
                      <label>{<IntlMessages id="form.ownerEmail" />}<span className="text-red">*</span></label>
                      <input
                        type="email"
                        onChange={e => {handleChange(e);
                          this.setState({apiEmailError:''})
                        }}
                        onBlur={e => {
                          handleBlur(e);
                           if(!errors.email && values.email!=''){
                            this.checkSuperAdmin(e.target.value);
                           };
                          // if(values.org_name && role == 1 ){this.checkOrgExistOrNot(values.org_id, values.email)};
                        }}
                        //placeholder={STRINGS[locale.locale].email}
                        defaultValue={values.email}
                        name="email"
                        margin="normal"
                        className="field form-control"
                        noValidate
                        autocomplete="new-password"
                      />
                      <EmailIcon className="icon" />
                      <p className="error">
                          {errors.email && touched.email ? (
                                <span>{errors.email}</span>
                          ) : null}
                          {apiEmailError}
                      </p>
                    </div>

                    <div className="form-group">
                      <label><IntlMessages id="form.distributorName" /><span className="text-red">*</span></label>
                      <input
                        type="text"
                        //placeholder={STRINGS[locale.locale].supplier}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        defaultValue={values.supplier}
                        name="supplier"
                        margin="normal"
                        className="field form-control"
                        noValidate
                      />
                      <PersonIcon className="icon" />
                      <p className="error">
                          {errors.supplier && touched.supplier ? (
                                <div>{errors.supplier}</div>
                          ) : null}
                          {responseError}
                      </p>
                    </div>

                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <Button
                        //disabled={this.state.isInValidForm}
                        disabled={ !(apiOrgError=='') || !(apiEmailError=='')}
                        // onClick={() => {
                        //   this.props.showAuthLoader();
                        // }}
                        type="submit"
                        color="primary"
                        className="btn-regiter"
                      >
                        <IntlMessages id="form.submit" />
                      </Button>
                    </div>
                    </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            </div>
          )}


          {this.state.success && (
            <div className="modal-content" >

              <div className="">
                    <button className="close"  onClick={()=>{this.props.closeModal()}} >
                         <span>&times;</span>
                    </button>
              </div>

              <div className="modal-body p-0">
                <p className="mt-3 mb-4 text-center success-msg">
                  {successMessage}
                </p>
              </div>

              {/* <div className="modal-footer text-center">
                <button className="btn btn-submit1" >
                  <Link to="/ea/signin"><img src={Login} alt="icon" style={{width: "20px"}} className="mr-2" />
                    <IntlMessages id="menu.login" />
                  </Link>
                </button>
              </div> */}

            </div>
          )}

          {this.state.isLoading && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}

        </div>
      </Modal>
    );
  }

}

const mapStateToProps = ({ auth, settings }) => {
  const { locale } = settings;
  return { locale};
};

export default connect(mapStateToProps, {
  userSignIn,
}) ( JoinNOw);