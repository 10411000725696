import React from 'react';
import Modal from "react-modal";
import { Redirect } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import { httpClient } from '../../appUtility/Api';
import { AUTH_URLS, STRINGS } from '../../constants/AppConst';
import { connect } from 'react-redux';
import { updateToken } from 'actions/Auth';
import OtpInput from 'react-otp-input';
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchemaCode = Yup.object({
  code: Yup.string()
    .required(<IntlMessages id="errors.required" />)
});



class ForgetPasswordVerification extends React.Component {

  constructor() {
    super();
    this.state = {
      verified: false,
      error: '',
      fpCode: '',
      isInValidForm: true,
      redirect: '/ea/reset-password',
      setPasswordToken: '',
    };

  }

  updateOTP = (otp) => this.setState({ fpCode:otp });

  sendVerificationCode = async (code) => {
    let postdata = {
      "code": code,
      "token": this.props.formdata.forgetToken
    };

    var res = await httpClient
      .post(AUTH_URLS.VERIFY_FP_TOKEN, postdata)
      .then((res) => { return res.data; })
      .catch((error) => {
        console.log(error.response.data);
        //this.state.error = error.response.data.error;
        this.setState({ error: error.response.data.error })
        return { status: 'fail' };
      });

    if (res.status === "success") {
      console.log('change password');

      this.props.updateToken({
        activation: '',
        forgetPassword: '',
        resetPassword: res.verified_token
      })

      this.setState({
        verified: true,
      });
    }
  }

  render() {

    const { locale } = this.props;
    const { setPasswordToken, error } = this.state;

    if (this.state.verified) {
      return <Redirect to={{ pathname: this.state.redirect, state: { token: setPasswordToken } }} />
    }

    return (
      <Modal
        isOpen={this.props.formdata.showModal}
        contentLabel="Register Verification"
        className="register-modal"
      >
        <div className="modal-dialog modal-dialog-centered">

          {!this.state.verified && (
            <div className="modal-content" >
              <h2 className="error text-center"><IntlMessages id="vivalife.doNotClose" /></h2>
              <div className="modal-header">
                {/* <button type="button" className="close" data-dismiss="modal">&times;</button> */}
                <h4 className="modal-title"><IntlMessages id="vivalife.emailVerification"  /></h4>
              </div>
             
              <Formik
                    initialValues={{ code: this.state.fpCode }}
                    validationSchema={validationSchemaCode}
                    enableReinitialize
                    onSubmit={(values) => {
                      this.sendVerificationCode(values.code);
                    }}
                  >
                    {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                       <div className="modal-body p-0 text-center">
                <div className="row justify-content-center">
                  <div className="col-md-10 form-group">
                    <p className="mt-3 mb-4 text-center">
                      {this.props.formdata.activateMessage}
                    {/* <br /><b> {this.props.formdata.email} </b> */}
                    </p>
                    {/* <label className="text-left d-block"><IntlMessages id="vivalife.verificationCode" /></label> */}
                    {/* <input
                      type="text"
                      onBlur={(e) => this.setState({ fpCode: e.target.value })}
                      placeholder={STRINGS[locale.locale].verificationCode}
                      defaultValue={this.state.fpCode}
                      name="fpCode"
                      margin="normal"
                      className="mt-0 mb-4 form-control"
                      noValidate
                    /> */}
                    <div className="org-inputs-group"> 
                      <OtpInput
                        value={this.state.fpCode }
                        onChange={e => {handleChange(e);
                          this.updateOTP(e);
                          this.setState({error:""})
                        }}
                        isInputNum={true}
                        numInputs={6}
                        separator={<span>-</span>}
                        name="code"
                        
                      />
                      <p className="error">
                        {error}
                      </p>
                      </div>
                  </div>
                </div>
               
                </div>
              <div className="modal-footer text-center">
                <button className="btn bg-purple text-white" disabled={error!='' || this.state.fpCode == ''} type="submit"> <IntlMessages id="form.submit" /> </button>
              </div>
              </form>
              )}

            </Formik>
             

            </div>

          )}

          {this.state.isLoading && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}

        </div>
      </Modal>
    );
  }

}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { locale } = settings;
  return { authUser, locale };
};


export default connect(mapStateToProps, {
  updateToken
})(ForgetPasswordVerification);