import React  ,{ useState, useEffect } from 'react';
import '../inner-style.scss';
import { httpClient } from '../../../../../appUtility/Api';
import { STATIC_PAGES_URLS } from '../../../../../constants/AppConst';
import Parser from 'html-react-parser';
import CircularProgress from "@material-ui/core/CircularProgress";

import Header from '../../../../../components/Header';

const Terms = ({match}) =>  {
    const params = new URLSearchParams(window.location.search)
  const lang =params.has('lang')?params.get('lang'):'en';
  const [termsBody, setTermsBody] = React.useState('');
  
  const [termsTitle, setTermsTitle] = React.useState('');

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    httpClient.get(STATIC_PAGES_URLS.TERMS+lang)
   .then(results => results.data)
   .then(data => {
    setTermsTitle(data.detail.title)
    setTermsBody(data.detail.body)
   });
}, []); 

  
    return (
      <div class="bg-gray w-100 m-h-100"> 
      {/* <Header/> */}
        {termsBody !="" && Parser(termsBody)}
        {termsBody=="" && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }


export default Terms;