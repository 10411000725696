import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  UPDATE_TOKEN,
  UPDATE_PROFILE_INFO
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: localStorage.getItem('login_user') ? JSON.parse(localStorage.getItem('login_user')) : '',
  login:localStorage.getItem('login_user') ? true:false,
  profile:{
    full_name:null,
    unread:0,
    email:null,
    newOrg:null
  },
  token:{
    activation:'',
    forgetPassword:'',
    resetPassword:''
  }
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        login:true,
      }
    }

    case UPDATE_TOKEN: {
      return {
        ...state,
        token: {
          activation:action.payload.activation,
          forgetPassword:action.payload.forgetPassword,
          resetPassword:action.payload.resetPassword
        }
      }
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/ea/dashboard/',
        loader: false,
        login:false,
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    } 
    case UPDATE_PROFILE_INFO: {
      return {
        ...state,
        profile: {
          full_name:action.payload.full_name,
          unread:action.payload.unread,
          email:action.payload.email,
          newOrg:action.payload.newOrg
        }
      }
    }
    default:
      return state;
  }
}
