import React from 'react';
import { Link } from 'react-router-dom';
import {switchLanguage} from '../../actions/Setting';
import { connect } from "react-redux";
import BurgerMenu from "./menu";


class Menu extends React.Component {
  
  constructor() {
    super();
    this.state = {
      menu: false
    };
  }

  componentDidMount() {
    const language = localStorage.getItem("language");
    if(!language){
      localStorage.setItem('language', this.props.locale);
    }
  }

  togglefn2() {
    this.setState({
      menu: !this.state.menu
    });
  }
  
  render() {
    const {
      locale,
      login
    } = this.props;
    //const {first_name, last_name}= this.props.authUser;
    // console.log('Locale',this.props);
    return (
          <div className="col-4">
            <div className="collapse-nav">
              <div className="d-flex align-items-center justify-content-end"> 
                <span className="user-name mr-3">
                   {/* {!login  && ( <IntlMessages id="vivalife.ea" /> )} */}
                   {login  && (<Link to="/ea/account"> { this.props.authUser.first_name} { this.props.authUser.last_name}  </Link> )}
                </span>
                <BurgerMenu  data={{isHome:this.props.data.isHome}} />
              </div>             
            </div>
          </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const {locale}=settings.locale;
  const login = auth.login;
  const {authUser} = auth;
  return { locale, login ,authUser };
  };
  
  export default connect(mapStateToProps, {
    switchLanguage
  })(Menu);