import React from "react";
import { Route, Switch, withRouter , Redirect} from "react-router-dom";

import {connect} from 'react-redux';
import Header from 'components/Header/index';
import TopNav from 'components/TopNav';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
// import ColorOption from 'containers/Customizer/ColorOption';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../../../util/asyncComponent';
//import TopNav from 'components/TopNav';
import {subscribe} from "./../../../appUtility/Socket";


class Dashboard extends React.Component {

  componentDidMount(){
    if(this.props.authUser && (typeof window.channel !== "object")){
      subscribe(this.props.authUser.channel);          
    }

  }

  render() {
    const {
      match,
      drawerType,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour/> */}

        <Sidebar />
        <div className="app-main-container">
          {/* <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div> */}

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <div className="app-wrapper">
               { !this.props.authUser.super_admin && <Switch>
                  <Route
                    path={`${match.url}/dashboard`}
                    component={asyncComponent(() => import("./routes/main"))}
                  />
                  <Route
                    path={`${match.url}/account`}
                    component={asyncComponent(() => import("./routes/profile/index"))}
                  />

                  <Route
                    path={`${match.url}/messages`}
                    component={asyncComponent(() => import("./routes/message/index"))}
                  />

                  <Route
                    path={`${match.url}/organization/settings`}
                    component={asyncComponent(() => import("./routes/settings/index"))}
                  />
                  
                  <Route
                    component={asyncComponent(() =>
                      import("app/modules/extraPages/routes/404")
                    )}
                  />
                </Switch> }

                { this.props.authUser.super_admin && 
                 <Redirect to={'/sa/dashboard/'}/> }
              </div>
            </div>
            <Footer />
          </main>
        </div>
        {/* <ColorOption/> */}
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { authUser, initURL, login } = auth;
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition, authUser, initURL, login};
};
export default withRouter(connect(mapStateToProps)(Dashboard));
