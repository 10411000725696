import React ,{ useState, useEffect }  from 'react';

import { httpClient } from '../../../../../appUtility/Api';
import { STATIC_PAGES_URLS } from '../../../../../constants/AppConst';
import Parser from 'html-react-parser';
//import Header from '../../../../../components/Header';
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from '../../../../../containers/StaticHeader/header';
// import IntlMessages from 'util/IntlMessages';


const FAQ = ({match}) =>  {
  const params = new URLSearchParams(window.location.search)
  var lang =params.has('lang')?params.get('lang'):null;
  var app =params.has('app')?false:true;
  const language = localStorage.getItem("language");

  if(lang==null){
    if(language==='zht'){
      lang = 'zhHant';
    }else if(language==='zhs'){
      lang = 'zhHans';
    }else if(language==='ms'){
      lang = 'ms';
    }else if(language==='en'){
      lang = 'en';
    }else{
      lang = 'en';
    }

  }
  const [faqBody, setFaqBody] = React.useState('');
  const [faqTitle,setFaqTitle]= React.useState('');

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    httpClient.get(STATIC_PAGES_URLS.FAQ+lang)
   .then(results => results.data)
   .then(data => {
    setFaqBody(data.detail.body);
    setFaqTitle(data.detail.title)
   });
}, []); // <-- Have to pass in [] here!


    return (
      <div className="bg-gray w-100 m-h-100"> 
                  {app && <Header/>}
                  {faqBody !="" && Parser(faqBody)}
                  {faqBody=="" && (
                    <div className="loader-view">
                      <CircularProgress />
                    </div>
                  )}
      </div>
    );
  }

export default FAQ;

