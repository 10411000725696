import React from 'react';
import Modal from "react-modal";
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import { httpClient } from '../../appUtility/Api';
import { AUTH_URLS,STRINGS } from '../../constants/AppConst';
import { Link } from 'react-router-dom';
import {  userSignIn } from '../../actions/Auth';
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchemaCode = Yup.object({
  code: Yup.string()
    .required(<IntlMessages id="errors.required" />)
});


class RegisterSuccess extends React.Component {

  constructor() {
    super();
    this.state = {
      modal: {
        code: '',
        token: ''
      },
      error: '',
      successMessage: '',
      success: false,
      isInValidForm: true,
      code:''
    };

  }

  updateOTP = (otp) => this.setState({ code:otp });

  sendActivationCode = async (code) => {

    let postdata = {
      "code": code, //this.state.activationCode,
      "token": this.props.formdata.token
    };

    var res = await httpClient
      .post(AUTH_URLS.ACCOUNT_ACTIVATE, postdata)
      .then((res) => { return res.data; })
      .catch((error) => {
        this.setState({ error: error.response.data.error });
        return { status: 'fail' };
      });

    if (res.status === "success") {
      this.setState({ success: true, successMessage: res.message });
      const userData = res.data;
      userData.token = res.token;
      this.props.userSignIn({ userData });

    }

  }

  render() {
    const { locale } = this.props;
    const { error, successMessage } = this.state;
    return (
      <Modal
        isOpen={this.props.formdata.showModal}
        contentLabel="Register Verification"
        className="register-modal"
      >
        <div className="modal-dialog modal-dialog-centered">

          {!this.state.success && (
            <div className="modal-content" >

                <div className="modal-header">
                   <button type="button" className="close" onClick={()=>{this.props.closeModal()}} >
                      <span  className="sub-nav-text" aria-hidden="true">&times;</span>
                   </button>
              </div>
                          
              <div className="modal-body p-0  text-center ">                
                <div className="row justify-content-center">
                  <div className="col-md-10 form-group">
                    <p className="mt-3 mb-4 text-center">
                      {this.props.formdata.registerSuccessMsg}
                    </p>
                  
                  </div>
                </div>
              </div>

              <div className="modal-footer text-center">
                <button className="btn btn-secondary" onClick={()=>{this.props.closeModal()}}>
                <IntlMessages id="vivalife.close" />
                </button>
               {/* <button className="btn bg-purple text-white" disabled={error!=''} type="submit"> <IntlMessages id="form.submit" /> </button> */}
                
              </div>
             


            </div>
          )}


          {this.state.success && (
            <div className="modal-content" >

              <div className="modal-header">
                    <button className="close" >
                       <Link to="/ea/signin">
                         <span>&times;</span>
                       </Link>
                    </button>
              </div>

              <div className="modal-body p-0">
                <p className="mt-3 mb-4 text-center">
                  {successMessage}
                </p>
              </div>

              {/* <div className="modal-footer text-center">
                <button className="btn btn-submit1" >
                  <Link to="/ea/signin"><img src={Login} alt="icon" style={{width: "20px"}} className="mr-2" />
                    <IntlMessages id="menu.login" />
                  </Link>
                </button>
              </div> */}

            </div>
          )}

          {this.state.isLoading && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}

        </div>
      </Modal>
    );
  }

}

const mapStateToProps = ({ auth, settings }) => {
  const { locale } = settings;
  return { locale};
};

export default connect(mapStateToProps, {
  userSignIn,
}) ( RegisterSuccess);