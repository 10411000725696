import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { hideMessage, showAuthLoader, userSignIn, updateProfileInfo } from 'actions/Auth';
import { updateMyOrganizations} from 'actions/DashboardAction';
import { httpClient } from '../appUtility/Api';
import {UpdateRegister,switchLanguage, setSuperFeatures} from 'actions/Setting'; 
import { AUTH_URLS , STRINGS} from '../constants/AppConst';
import { showErrorToaster } from 'appUtility/commonFunction';
// import axios from 'axios'
import Header from "./CommonHeader/header";
import Logo from "../assets/images/logo2.png";
import EmailIcon from '@material-ui/icons/Email';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Popup from "./Popups/AccountActivation";
import ExpiredToken from "./Popups/ExpiredPopup";
import ApplyNowPopup from "./Popups/Register";
import { Formik } from "formik";
import * as Yup from "yup";


const validationSchema = Yup.object({
  _email: Yup.string()
    .required(<IntlMessages id="errors.required" />)
    .email(<IntlMessages id="errors.emailInvalid" />),
  password: Yup.string()
    .required(<IntlMessages id="errors.required" />),
});

const validationSchemaForAppUser = Yup.object({
  _email: Yup.string()
    .required(<IntlMessages id="errors.required" />)
    .email(<IntlMessages id="errors.emailInvalid" />),
  password: Yup.string()
    .required(<IntlMessages id="errors.required" />),
    agree: Yup.string()
    .required(<IntlMessages id="errors.required" />),
});


class SignIn extends React.Component {

  constructor() {
    super();
    this.state = {
      isLoading: false,
      hidden: true,
      apiError:'',
      showModal:false,
      token: '',
      registerSuccessMsg:'',
      emailv:'',
      tokenize:false,
      is_enterprise:1,
      tokenValue:null,
      accessTokenValue:null,
      redirectTokenValue:null,
      redirect:false,
      applyNowModal:false
    };
    this.toggleShow = this.toggleShow.bind(this);

  }
  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount(){
    const params = new URLSearchParams(window.location.search);
    const token =params.has('_token')?params.get('_token'):null;
    if(token){
      // let base64ToString = Buffer.from(token, "base64").toString();
      // this.setState({"emailv":base64ToString});
      this.verifyToken(token);
    }

    const access_token =params.has('access_token')?params.get('access_token'):null;
    if(access_token){
      this.verifyAccessToken(access_token);
    }

  }

  verifyToken =  (token) => {
  let postdata = {};
   httpClient
    .post(AUTH_URLS.VERIFY_REGISTER_TOKEN+token, postdata)
    .then((res) => { 
      if(res.data.data.is_register){
      this.setState({emailv:res.data.data.email,tokenize:res.data.data.is_valid,tokenValue:token,is_enterprise:res.data.data.is_enterprise});
      }else{
      this.setState({emailv:res.data.data.email,tokenize:res.data.data.is_valid,redirectTokenValue:'token='+token,redirect:true});
      }
    })
    .catch((error) => {

      if(Array.isArray(error.response.data.errors)){
        this.setState({apiError:error.response.data.errors[0]});
      }else{
        this.setState({apiError:error.response.data.error});
      }
    });
};

verifyAccessToken =  (token) => {
  let postdata = {};
   httpClient
    .post(AUTH_URLS.VERIFY_ACCESS_TOKEN+token, postdata)
    .then((res) => { 
      if(res.data.data.is_register){
      this.setState({emailv:res.data.data.email,tokenize:res.data.data.is_valid,accessTokenValue:token,is_enterprise:res.data.data.is_enterprise});
      }else{
      this.setState({emailv:res.data.data.email,tokenize:res.data.data.is_valid,redirectTokenValue:'access_token='+token,redirect:true});
      }
    })
    .catch((error) => {

      if(Array.isArray(error.response.data.errors)){
        this.setState({apiError:error.response.data.errors[0]});
      }else{
        this.setState({apiError:error.response.data.errors});
      }
    });
};

  componentDidUpdate() {
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  
  togglepopup = () =>{
    //closeModal
    this.setState({showModal:false});
  }

  handleUserSignIn = async (email, password) => {
    this.setState({
      isLoading: true,
    });
    try {
      httpClient
        .post(AUTH_URLS.USER_SIGNIN, {
          email: email,
          password: password,
          token:this.state.tokenValue,
          access_token:this.state.accessTokenValue,
          web_agree : (this.state.tokenValue!=null || this.state.accessTokenValue!=null)?1:0
        })
        .then((res) => {
          //console.log(res);
          this.setState({
            isLoading: false,
          });

          this.props.UpdateRegister({regByToken:true,regToken:''});
          
          if(res.data.activated =='active'){
          const userData = res.data.data;
            userData.token = res.data.token;
            this.props.updateMyOrganizations(userData.organizations);
            var lang = userData.language == 'zhHans' ?'zhs': userData.language== 'zhHant'?'zht':userData.language== 'ms'?'ms':'en';
            
            this.props.updateProfileInfo({
              full_name:"",
              unread:userData.unread,
              email:null,
              newOrg:userData.newOrgs
            });
            localStorage.setItem('language', lang);
            this.props.switchLanguage(lang);
            this.props.userSignIn({ userData });
            if(userData.super_admin){
              this.props.setSuperFeatures(
                userData.super_features.features.password_update
              );
            }
            if(this.state.tokenValue!=null || this.state.accessTokenValue!=null){
              this.props.history.push("/ea/messages");
            }
          }else if(res.data.activated == 'inactive'){

            this.setState({             
              registerSuccessMsg: res.data.message, 
              token: res.data.token , 
              showModal: true}
              );
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            apiError: err.response.data.error
          });
        });
    } catch (error) {
      this.setState({
        isLoading: false,
        apiError: error.response.data.error
      });
      //showErrorToaster(error)
    }
  };

  applyNow =()=>{
    this.setState({applyNowModal:!this.state.applyNowModal})
  }

  render() {
    const {  isLoading, apiError,emailv, redirect, tokenize,is_enterprise, applyNowModal} = this.state;
    const {  locale, login } = this.props;

    const params = new URLSearchParams(window.location.search)
    const token = params.has('token') || params.has('_token')  || params.has('access_token')?true:null;
    
    if (login === true && !token ) {
      return ( <Redirect to={'/ea/dashboard/'}/> );
    }

    if (redirect ) {
      return <Redirect to={'/ea/signup?'+this.state.redirectTokenValue} />
    }

    return (
      <div className="w-100 bg-gray m-h-100 signin-page">
        <Header />
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-5">
              <div className="left-section">
                <div className="logo">
                  <Link to="/index"><img src={Logo} alt="Logo" className="logo" /></Link>
                </div>
                <div className="already-text">
                  <span className="d-inline-block mr-2"><IntlMessages id="vivalife.donthaveaccount" /></span>
                  <a href="#" onClick={()=>{ this.applyNow();}}><IntlMessages id="vivalife.applyNow" /></a>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="app-login-content">
                <div className="app-login-header">
                  <h2> 
                   <IntlMessages id="vivalife.login" /> 
                  </h2>                  
                </div>
                {tokenize && <div className="login-notifation">
                  <IntlMessages id="vivalife.signuptokenmessage" />
                </div>
                }

                <div className="app-login-form">
                <Formik
                    initialValues={{ _email: emailv, password:"",agree:''}}
                    validationSchema={tokenize?validationSchemaForAppUser:validationSchema}
                    enableReinitialize
                    onSubmit={(values) => {
                      this.handleUserSignIn(values._email, values.password);
                    }}
                  >
                    {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                    <form onSubmit={handleSubmit} >
                    <fieldset>
                      <div className="form-group">
                        <label>{<IntlMessages id="form.email" />}<span className="text-red">*</span></label>
                          <input
                            placeholder={STRINGS[locale.locale].email}
                            onBlur={handleBlur}
                            onChange={e => {handleChange(e);
                              this.setState({apiError:''})
                            }}
                            defaultValue={values._email}
                            margin="normal"
                            name="_email"
                            readOnly={tokenize}
                            autocomplete="new-password"
                            className="field form-control"
                          />
                        <EmailIcon className="icon" />
                          <p className="error">
                          { errors._email && touched._email ? (
                                <span>{errors._email}</span>
                          ) : null}
                          </p>
                      </div>
                      <div className="form-group mb-3">
                        <label>{<IntlMessages id="form.password" />}<span className="text-red">*</span></label>
                          <input
                            type={this.state.hidden ? 'password' : 'text'}
                            placeholder={STRINGS[locale.locale].password}
                            onBlur={handleBlur}
                            onChange={e => {handleChange(e);
                              this.setState({apiError:''})
                            }}
                            defaultValue={values.password}
                            margin="normal"
                            name="password"
                            autocomplete="new-password"
                            className="field form-control"
                          />
                        <VisibilityIcon className="icon" onClick={this.toggleShow} />
                          <p className="error">
                          {errors.password && touched.password ? (
                                <span>{errors.password}</span>
                          ) : null}
                          {apiError!="invalid" && apiError}
                          </p>
                      </div>
                    
                      {tokenize && is_enterprise!=1 && <div className="form-group">
                      <label className="custom-checkbox hyper" id="custom-checkbox">
                        <IntlMessages id="form.terms1" />
                        <a href="/e/privacy" target="_blank"><IntlMessages id="menu.privacy" /> </a>
                        <IntlMessages id="form.terms2" />
                        <a href="/e/terms" target="_blank"><IntlMessages id="menu.terms" /> </a>
                        <input 
                          type="checkbox" 
                          name="agree"  
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <p className="error">
                          {errors.agree && touched.agree ? (
                                <span>{errors.agree}</span>
                          ) : null}
                      </p>
                    </div> }
                      <div className="mb-3 text-right forget-pwd" >
                          <Link to="/ea/forgot-password">
                            <IntlMessages id="vivalife.forgetpassword" />
                          </Link>
                      </div>

                      <div className="mb-3 d-flex align-items-center justify-content-between">
                          <Button
                            onClick={() => {
                              this.props.showAuthLoader();
                            }}
                            type="submit"
                            //disabled={values.email === '' || values.password === ''}
                            variant="contained"
                            color="primary"
                          >
                          <IntlMessages id="form.login" />
                        </Button>
                      </div>
                    </fieldset>
                    </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>

        {applyNowModal && <ApplyNowPopup  formdata = {{showModal:applyNowModal}}  closeModal={this.applyNow.bind(this)} > </ApplyNowPopup>}

        {this.state.showModal && (
          <Popup formdata = {this.state} closeModal={this.togglepopup.bind(this)}/>
        )}
        {apiError=="invalid" && 
        <ExpiredToken   formdata = {{showModal:true}} />
        }

        {isLoading && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { loader, alertMessage, showMessage, authUser, login } = auth;
  const { locale } = settings;
  return { loader, alertMessage, showMessage, authUser ,locale, login};
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  updateMyOrganizations,
  UpdateRegister,
  switchLanguage,
  updateProfileInfo,
  setSuperFeatures
})(SignIn);
