import React from 'react';
import { Link } from 'react-router-dom';
import HomeHeader from "../Menu/homeHeader";
import Logo from "../../assets/images/logo.png";

class Header extends React.Component {

  render() {
    return (
      <div className="splash-page" id="header3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-4">
              <div className="logo">
              <Link to="/index"><img src={Logo} alt="Logo"/></Link>
              </div>
            </div>  
            <HomeHeader  data={{isHome:false}} />
          </div>
         </div>
      </div>
    );
  }
}

export default Header;