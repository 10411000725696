import axios from 'axios';
import { API_BASE_URL } from '../constants/AppConst';

export const getAccessToken = () => {
  const tokens = localStorage.getItem('tokens');
  if (tokens) {
    const tokenData = JSON.parse(tokens);
    return `${tokenData}`;
  }
  return null;
};

export const httpClient = axios.create({
  baseURL: API_BASE_URL,
});

httpClient.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    const language = localStorage.getItem("language");
    var xlocal='en';

    if(language==='zht'){
      xlocal = 'zhHant';
    }else if(language==='zhs'){
      xlocal = 'zhHans';
    }else if(language==='ms'){
      xlocal = 'ms';
    }else if(language==='en'){
      xlocal = 'en';
    }

    if (token) {
      config.headers['Authorization'] ="Bearer  " + token;
    }
    config.headers['TimeZone'] =Intl.DateTimeFormat().resolvedOptions().timeZone;
    config.headers['X-localization'] = xlocal;
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    console.log('sdfdf', error);
    Promise.reject(error);
  }
);
