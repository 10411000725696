import enLang from './entries/en-US';
import ZHsLang from './entries/zhs-CN';
import ZHtLang from './entries/zht-CN';
import msLang from './entries/ms-MYS';
import {addLocaleData} from 'react-intl';

const AppLocale = {
  en: enLang,
  zhs : ZHsLang,
  zht : ZHtLang,
  ms  : msLang
};
addLocaleData([AppLocale.en.data,AppLocale.zhs.data,AppLocale.zht.data,AppLocale.ms.data]); 
export default AppLocale;
