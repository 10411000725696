import React from 'react';
// import { Link } from 'react-router-dom';
import $ from "jquery";
import Header from "./HomeHeader/header";
import Hand from "../assets/images/hand1.png";
import Gif from "../assets/images/hand2.gif";
import GooglePay from "../assets/images/google-paly.png";
import IphoneStore from "../assets/images/iphone-store.png";
import Dashboard1 from "../assets/images/dash1.png";
import Dashboard2 from "../assets/images/dash2.png";
import Dashboard3 from "../assets/images/dash3.png";
import IntlMessages from "util/IntlMessages";
import SSL from "../assets/images/positivessl_trust_seal_lg_222x54.png"
import {APP_IOS_URL, APP_APK_URL} from "constants/AppConst";
// import Footer from "../components/Footer"
// import dashboard from '../app/modules/dashboard';
// import Logo from "../assets/images/logo2.png";



class Index extends React.Component {


  render() {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $('#landing').addClass('scroll');
      } else {
        $('#landing').removeClass('scroll');
      }
    });
    return (
      <div className="landing-page" id="landing">
        <Header />
        <div className="hero-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-xl-5 order-2 order-lg-1">
                <div className="hang-img">
                  <img src={Hand} className="img-fluid overlay-img" alt="hang-img" />
                  <img src={Gif} className="img-fluid gif-img" alt="hang-img" />
                </div>
              </div>
              <div className="col-lg-7 col-xl-7 order-1 order-lg-2">
                <div className="hero-content">
                  <h2><IntlMessages id="home.sec1.text1" /></h2>
                  <p><IntlMessages id="home.sec1.text2" /></p>
                  <div className="download-app">
                    <h4> <IntlMessages id="home.download" /></h4>
                    <div className="btn-group">

                      <a href={APP_APK_URL} className="mr-3" target="_blank"><img src={GooglePay} className="ing-fluid" /></a>

                      <a href={APP_IOS_URL}>
                        <img src={IphoneStore} className="ing-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div id="dashboard_slider" className="carousel slide" data-ride="carousel">
                  <ul className="carousel-indicators">
                    <li data-target="#dashboard_slider" data-slide-to="0" className="active"></li>
                    <li data-target="#dashboard_slider" data-slide-to="1"></li>
                    <li data-target="#dashboard_slider" data-slide-to="2"></li>
                  </ul>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={Dashboard1} className="img-fluid" alt="dashboard" />
                    </div>
                    <div className="carousel-item">
                      <img src={Dashboard2} className="img-fluid" alt="dashboard" />
                    </div>
                    <div className="carousel-item">
                      <img src={Dashboard3} className="img-fluid" alt="dashboard" />
                    </div>
                  </div>
                </div>
                {/* <div class="section-img">
                      <img src={Dashboard}  className="img-fluid" alt="dashboard"/>
                      </div>  */}
              </div>
              <div className="col-lg-6">
                <div className="section-content">
                  <h2><IntlMessages id="home.sec2.text0" /></h2>
                  <p><IntlMessages id="home.sec2.text1" /></p>
                  <p><IntlMessages id="home.sec2.text2" /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="">
          <span className="d-inline-block">
            <IntlMessages id="vivalife.copyright1" />
            {new Date().getFullYear()}
            <IntlMessages id="vivalife.copyright2" />
          </span>
          <div> <img src={SSL}></img></div>
        </footer>
      </div>
    );
  }
}

export default Index;
