import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link,Redirect } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { hideMessage, showAuthLoader, userSignUp, updateProfileInfo} from 'actions/Auth';
import { httpClient } from '../appUtility/Api';
import { AUTH_URLS, STRINGS } from '../constants/AppConst';
import Header from "./CommonHeader/header";
import Logo from "../assets/images/logo2.png";
import EmailIcon from '@material-ui/icons/Email';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PersonIcon from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Popup from "./Popups/RegisterSuccess";
import {UpdateRegister} from 'actions/Setting'; 
import { Formik } from "formik";
import * as Yup from "yup";
import {  userSignIn } from 'actions/Auth';



//(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,12}
///^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
const validationSchema = Yup.object({
  email: Yup.string()
    .required(<IntlMessages id="errors.required" />)
    .email(<IntlMessages id="errors.emailInvalid" />),
  org_name: Yup.string()
    .required(<IntlMessages id="errors.required" />),
  supplier: Yup.string()
    .required(<IntlMessages id="errors.required" />)
});


class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      isLoading:false,
      showModal: false,
      registerSuccessMsg: '',
      token: '',
        email:'',
        org:'',
        role:1,
      apiEmailError:'',
      apiOrgError:'',
      responseError:'',
      hidden:true,
      isMoreOrgs:false,
      redirect:false,
      tokenValue:null
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }


  componentDidMount = ()=>{
    const params = new URLSearchParams(window.location.search);
    const token =params.has('token')?params.get('token'):null;
    if(token){
      this.verifyToken(token);
    }

    const access_token =params.has('access_token')?params.get('access_token'):null;
    if(access_token){
      this.verifyAccessToken(access_token);
    }

    
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  componentDidUpdate() {
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  handleUserSignUp = async (email, org_name, supplier) => {
    this.setState({
      isLoading: true,
      email:email
    });

    try {
      const signUpResponse = await this.createUserWithEmailPasswordRequest(email, org_name, supplier);
      if (signUpResponse.status === 200) {
        this.setState({ 
          isLoading: false, 
          registerSuccessMsg: signUpResponse.data.message, 
          showModal: true}
          );
      }
      else if (signUpResponse.status === "fail") {
        this.setState({
          isLoading: false,
          showModal: false
        });
      }

    } catch (error) {
      if (error.response) {

        if(Array.isArray(error.response.data.error)){
          this.setState({responseError:error.response.data.error[0],isLoading: false, showModal: false});
        }else{
          this.setState({responseError:error.response.data.error,isLoading: false, showModal: false});
        }
      
      } else {
        this.setState({
          isLoading: false,
          showModal: false
        });
       
      }
    }
  };

  createUserWithEmailPasswordRequest = async (email, org_name, supplier) => {
    const language = localStorage.getItem("language");
    var xlocal='en';

    if(language==='zht'){
      xlocal = 'zhHant';
    }else if(language==='zhs'){
      xlocal = 'zhHans';
    }else if(language==='en'){
      xlocal = 'en';
    }

    return await httpClient.post(AUTH_URLS.DASHBOARD_REGISTER, {
      org_name: org_name,
      distributor_name: supplier,
      email: email,
      language:xlocal
    });
  };

/* start  realtime  validation */
verifyToken =  (token) => {
  let postdata = {};
   httpClient
    .post(AUTH_URLS.VERIFY_REGISTER_TOKEN+token, postdata)
    .then((res) => { 
      //console.log(res.data);
      // if(!res.data.data.is_register){
      // this.setState({email:res.data.data.email, org:res.data.data.org,role:res.data.data.role,tokenValue:token});
      // }else{
      // this.setState({email:res.data.data.email, org:res.data.data.org,role:res.data.data.role,tokenValue:'_token='+token, redirect:true});
      // }
    })
    .catch((error) => {

      if(Array.isArray(error.response.data.errors)){
        this.setState({responseError:error.response.data.errors[0]});
      }else{
        this.setState({responseError:error.response.data.error});
      }
    });
};

// verifyAccessToken =  (verifyAccessToken) => {
//   let postdata = {};
//    httpClient
//     .post(AUTH_URLS.VERIFY_ACCESS_TOKEN+verifyAccessToken, postdata)
//     .then((res) => { 
//       console.log(res.data);
//       if(!res.data.data.is_register){
//       this.setState({email:res.data.data.email, org:res.data.data.org,role:res.data.data.role,tokenValue:verifyAccessToken});
//       if(res.data.data.total_org_count > 1){
//         this.setState({isMoreOrgs:true});
//       }
//       }else{
//       this.setState({email:res.data.data.email, org:res.data.data.org,role:res.data.data.role,tokenValue:'access_token='+verifyAccessToken, redirect:true});
//       }
//     })
//     .catch((error) => {

//       if(Array.isArray(error.response.data.errors)){
//         this.setState({responseError:error.response.data.errors[0]});
//       }else{
//         this.setState({responseError:error.response.data.errors});
//       }
//     });
// };



  // checkEmailExistOrNot =  (email) => {
  //   let postdata = { "email": email };
  //    httpClient
  //     .post(AUTH_URLS.CHECK_EMAIL_EXIST, postdata)
  //     .then((res) => { 
  //       //console.log(res.data.no_of_orgs);
  //       if(res.data.no_of_orgs > 1){
  //         this.setState({isMoreOrgs:true});
  //       }
  //       this.setState({apiEmailError:''});
  //     })
  //     .catch((error) => {
  //       if(Array.isArray(error.response.data.errors)){
  //         this.setState({apiEmailError:error.response.data.errors[0]});
  //       }else{
  //         this.setState({apiEmailError:error.response.data.errors});
  //       }
  //     });
  // };

  // checkOrgExistOrNot =  (orgid, email) => {
  //   let postdata = { "email": email, "org_id": orgid };
  //     httpClient
  //     .post(AUTH_URLS.CHECK_ORG_EXIST, postdata)
  //     .then((res) => {
  //       this.setState({apiOrgError:''});
  //     })
  //     .catch((error) => {
  //       if(Array.isArray(error.response.data.errors)){
  //         this.setState({apiOrgError:error.response.data.errors[0]});
  //       }else{
  //         this.setState({apiOrgError:error.response.data.errors});
  //       }
  //     });
  // };

  /* end   realtime  validation */

  // toggelPassword =()=>{
  //   this.setState({hidden:!this.state.hidden});
  // }

  togglepopup = () =>{
    this.setState({showModal:false});
    window.location.reload();
  }


  render() {
    const { apiEmailError, apiOrgError, hidden, email, org, role, responseError, redirect } = this.state;
    const { locale, login } = this.props;

    const params = new URLSearchParams(window.location.search)
    const token = params.has('token') || params.has('_token') || params.has('access_token')?true:null;

    if (login === true && !token ) {
      return ( <Redirect to={'/ea/dashboard/'}/> );
    }

    // if (login !== true && token===null ) {
    //   return ( <Redirect to={'/index'}/> );
    // }

    if (redirect ) {
      return <Redirect to={'/ea/signin?'+this.state.tokenValue} />
    }

    return (
      <div className="w-100 bg-gray m-h-100 signin-page">
        <Header />
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-5">
              <div className="left-section">
                <div className="logo">
                  <Link to="/index"><img src={Logo} alt="Logo" className="logo" /></Link>
                </div>
                <div className="already-text">
                  <span className="d-inline-block mr-2"> <IntlMessages id="vivalife.alreadyhaveaccount" /></span>
                  <Link to="/ea/signin"><IntlMessages id="vivalife.login" /></Link>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="app-login-content">
                <div className="app-login-header text-left" id="app-register-header">
                  {/* <h1><IntlMessages id="vivalife.register" /></h1> */}
                  <span ><IntlMessages id="vivalife.dashboardSetup" /></span>
                </div>
                <div className="app-login-form" id="register-form"> 
                <Formik initialValues={{ email:"", org_name:"",supplier:"" }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      this.handleUserSignUp(values.email, values.org_name, values.supplier);
                      //resetForm();
                    }}
                >

                    {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                      
                    <div className="form-group">
                      <label><IntlMessages id="form.organisationDashboardName" /><span className="text-red">*</span></label>
                      <input
                        type="text"
                        //placeholder={STRINGS[locale.locale].organisation}
                        onChange={e => {handleChange(e);
                          //this.setState({apiOrgError:''})
                        }}
                        onBlur={e => {
                          handleBlur(e);
                          //if(e.target.value && !errors.org_name && role == 1 ){this.checkOrgExistOrNot(e.target.value, values.email)};
                        }}
                        defaultValue={values.org_name}
                        name="org_name"
                        margin="normal"
                        className="field form-control"
                        noValidate
                      />
                       {( this.state.isMoreOrgs && <div class="add-org" >
                              <i class="fas fa-plus" ></i>
                        </div> )}
                      <GroupWorkIcon className="icon" />
                      <p className="error">
                          {errors.org_name && touched.org_name ? (
                                <span>{errors.org_name}</span>
                          ) : null}
                          {apiOrgError}
                      </p>
                    </div>

                    <div className="form-group">
                      <label>{<IntlMessages id="form.ownerEmail" />}<span className="text-red">*</span></label>
                      <input
                        type="email"
                        onChange={e => {handleChange(e);
                         // this.setState({apiEmailError:''})
                        }}
                        onBlur={e => {
                          handleBlur(e);
                          // if(!errors.email &&  values.email!='' && role == 1){
                          //  this.checkEmailExistOrNot(e.target.value);
                          // };
                          // if(values.org_name && role == 1 ){this.checkOrgExistOrNot(values.org_id, values.email)};
                        }}
                        //placeholder={STRINGS[locale.locale].email}
                        defaultValue={values.email}
                        name="email"
                        margin="normal"
                        className="field form-control"
                        noValidate
                        autocomplete="new-password"
                      />
                      <EmailIcon className="icon" />
                      <p className="error">
                          {errors.email && touched.email ? (
                                <span>{errors.email}</span>
                          ) : null}
                          {apiEmailError}
                      </p>
                    </div>

                    <div className="form-group">
                      <label><IntlMessages id="form.distributorName" /><span className="text-red">*</span></label>
                      <input
                        type="text"
                        //placeholder={STRINGS[locale.locale].supplier}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        defaultValue={values.supplier}
                        name="supplier"
                        margin="normal"
                        className="field form-control"
                        noValidate
                      />
                      <PersonIcon className="icon" />
                      <p className="error">
                          {errors.supplier && touched.supplier ? (
                                <div>{errors.supplier}</div>
                          ) : null}
                          {responseError}
                      </p>
                    </div>

                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <Button
                        //disabled={this.state.isInValidForm}
                        //disabled={!(apiEmailError=='') ||  !(apiOrgError=='')}
                        onClick={() => {
                          this.props.showAuthLoader();
                        }}
                        type="submit"
                        color="primary"
                      >
                        <IntlMessages id="form.register" />
                      </Button>
                    </div>
                    </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {this.state.showModal && (
          <Popup formdata = {this.state} closeModal={this.togglepopup.bind(this)}/>
        )}
        
        {this.state.isLoading && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth,settings }) => {
  const { loader, alertMessage, showMessage, authUser,login } = auth;
  const { locale , regToken} = settings;
  return { loader, alertMessage, showMessage, authUser,locale, regToken,login };
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader,
  UpdateRegister,
  userSignIn,
  updateProfileInfo
})(SignUp);
