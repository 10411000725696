import React from 'react';
// import MenuIcon from '@material-ui/icons/Menu';
// import ClearIcon from '@material-ui/icons/Clear';
// import { Link } from 'react-router-dom';
import CommonHeader from "../Menu/commonHeader";
// import Logo from "../../../../../assets/images/logo.png";
// import Select  from 'react-select';
import { switchLanguage } from '../../actions/Setting';
import IntlMessages from "util/IntlMessages";
import Footer from 'components/Footer';


import { connect } from "react-redux";
// const options = [
//   {
//     label: "English",
//     value: "en",
//   },
//   {
//     label: "中文简体",
//     value: "zhs",
//   },
//   {
//     label: "中文繁體",
//     value: "zht",
//   }
// ];
class Header extends React.Component {

  constructor() {
    super();
    this.state = {
      menu: false,
      optionsdata: [
        { value: 'en', label: 'English' },
        { value: 'zht', label: '中文繁體' },
        { value: 'zhs', label: '中文简体' }
      ]
    };



  }
  togglefn2() {
    this.setState({
      menu: !this.state.menu
    });
  }
  onSelectLangauge = (e) => {
    e.preventDefault();
    this.props.switchLanguage(e.target.value === "en" ? "en" : e.target.value);

  }

  render() {
    // const {
    //   locale
    // } = this.props;
    console.log('Locale', this.props);
    return (
      <div className="splash-page">
        <div className="container border-bottom">
          <div className="row align-items-center">
            <div className="col-8">
              <div className="title">
                <h2><IntlMessages id="vivalife.ea" /></h2>
              </div>
            </div>
            <CommonHeader  data={{isHome:false}} />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings.locale;
  return { locale };
};

export default connect(mapStateToProps, {
  switchLanguage
})(Header);