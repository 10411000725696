import {all} from 'redux-saga/effects';
 import authSagas from './Auth';
// import echoSagas from './Echo';
// import pocketSagas from './Pocket';

export default function* rootSaga(getState) {
  yield all([  
    authSagas()
  ]);
}
