import Pusher from "pusher-js";
//import Echo from "laravel-echo";
import {PUSHER} from "./../constants/AppConst";

export const subscribe =(CH)=>{

  if(typeof window.channel !== "object" || window.channel.subscribed != true){
    const pusher = new Pusher(PUSHER.KEY, {
      cluster: PUSHER.CLUSTER
    });
    window.channel = pusher.subscribe(CH);
  }
}
